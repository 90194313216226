import React from "react";

export const NEWS2022 = {
  name: "2022",
  news: [
    {
      date: "Oct. 28, 2022",
      content: (
        <span>
          Christian Seto won an award for excellent innovative research at the
          NCITE annual meeting, congrats Christian!
        </span>
      ),
    },
    {
      date: "Oct. 25, 2022",
      content: (
        <span>
          Nicholas Ho and John Kevin Cava's paper, "Learning Free Energy
          Pathways through Reinforcement Learning of Adaptive Steered Molecular
          Dynamics," has been accepted to the Neural Information Processing
          Systems 2022 Workshop. Congratulations!
        </span>
      ),
    },
    {
      date: "Oct. 24, 2022",
      content: (
        <span>
          Jian Kang and Tiankai Xie et al.'s paper, "InfoFair:
          Information-Theoretic Intersectional Fairness" has been accepted to
          the 2022 IEEE International Conference on Big Data. Congratulations!
        </span>
      ),
    },
    {
      date: "Oct. 16, 2022",
      content: (
        <span>
          Arlen Fan finished his Doctoral Colloquium at IEEE VIS 2022.
          Congratulations!
        </span>
      ),
    },
    {
      date: "Jun. 30, 2022",
      content: (
        <span>
          Arlen Fan is accepted to present at the IEEE VIS 2022 Doctoral
          Colloquium. Congratulations!
        </span>
      ),
    },
    {
      date: "Jun. 27, 2022",
      content: (
        <span>
          Rosty Hnatyshyn successfully defended his Master's Thesis "A Visual
          Analytics Workflow for Detecting Transition Regions in Long Duration
          Molecular Dynamics Simulations". Congratulations Rosty!
        </span>
      ),
    },
    {
      date: "Jun. 17, 2022",
      content: (
        <span>
          Rui Zhang successfully defended her PhD's Thesis "Methods for
          Multiclass Geospatial Data Visualization". Congratulations Rui!
        </span>
      ),
    },
    {
      date: "Jun. 1, 2022",
      content: (
        <span>
          Yixuan Wang passed her comprehensive exam. Congratulations Yixuan!
        </span>
      ),
    },
    {
      date: "April 30, 2022",
      content: (
        <span>
          Arlen Fan's paper, "Annotating Line Charts in the Wild," was selected
          for an honorable mention award from CHI 2022. Congratulations Arlen!
        </span>
      ),
    },
    {
      date: "Feb. 12, 2022",
      content: (
        <span>
          Arlen Fan's paper, "Annotating Line Charts in the Wild," was accepted
          to CHI 2022. Congratulations Arlen!
        </span>
      ),
    },
    {
      date: "Feb. 1, 2022",
      content: (
        <span>
          Professor Ross Maciejewski was selected for the 2021 IEEE TVCG Best
          Associate Editor Award
        </span>
      ),
    },
  ],
};
