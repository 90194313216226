import React, {PureComponent} from 'react';
import {Row, Col, Tabs, List} from "antd";

import {NEWS2020} from "../data/news/2020";
import {NEWS2019} from "../data/news/2019";
import {NEWS2018} from "../data/news/2018";
import {NEWS2017} from "../data/news/2017";
import {NEWS2016} from "../data/news/2016";
import {NEWS2015} from "../data/news/2015";
import {NEWS2014} from "../data/news/2014";
import {NEWS2013} from "../data/news/2013";
import {NEWS2012} from "../data/news/2012";
import {NEWS2011} from "../data/news/2011";
import { NEWS2021 } from '../data/news/2021';
import { NEWS2022 } from '../data/news/2022';
import { NEWS2023 } from '../data/news/2023';
import { NEWS2024 } from '../data/news/2024';


const newsSets = [
    NEWS2024,
    NEWS2023,
    NEWS2022,
    NEWS2021,
    NEWS2020,
    NEWS2019,
    NEWS2018,
    NEWS2017,
    NEWS2016,
    NEWS2015,
    NEWS2014,
    NEWS2013,
    NEWS2012,
    NEWS2011
];


export default class News extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Tabs defaultActiveKey={newsSets[0].name}>
                {newsSets.map(newsObj =>
                    <Tabs.TabPane
                        tab={newsObj.name}
                        key={newsObj.name}
                    >
                        <List
                            itemLayout="vertical"
                            size="large"
                            dataSource={newsObj.news}
                            renderItem={(news, i) => (
                                <List.Item
                                    key={i}
                                >
                                    <Row gutter={64}>
                                        <Col span={4}>
                                            {news.date}
                                        </Col>
                                        <Col span={20}>
                                            {news.content}
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    </Tabs.TabPane>
                )}
            </Tabs>
        );
    }
}