import React from "react";

export const NEWS2021 = {
  name: "2021",
  news: [
    {
      date: "Oct. 29, 2021",
      content: (
        <span>
          Tiankai Xie is giving VIS Full Paper Presentation of paper FairRankVis: A Visual Analytics Framework for Exploring Algorithmic Fairness in Graph Mining Models.
        </span>
      ),
    },
    {
      date: "Oct. 27, 2021",
      content: (
        <span>
          Yuxin Ma is giving VIS CG&A Presentation of paper Exploring the Design Space of Sankey Diagrams for the Food-Energy-Water Nexus.
        </span>
      ),
    },
    {
      date: "Sept. 24, 2021",
      content: (
        <span>
          Collaborative work led by our colleague Sumana Chakrabarti-Bell on
          "Flour Quality Effects on Percolation of Gas Bubbles in Wheat Flour
          Doughs" has been accepted for publication. Congratulations everyone!
        </span>
      ),
    },
    {
      date: "Sept. 17, 2021",
      content: (
        <span>
          Tiankai Xie successfully completed his PhD proposal examination.
          Congratulations Tiankai!
        </span>
      ),
    },
    {
      date: "Sept. 12, 2021",
      content: (
        <span>
          Yafeng Lu's paper, Evaluating Forecasting, Knowledge, and Visual
          Analytics, was accepted to the Workshop on Trust and Expertise at IEEE
          VIS 2021. Congrats Yafeng!
        </span>
      ),
    },
    {
      date: "Aug. 24, 2021",
      content: (
        <span>
          Rui Zhang's paper, A Visual Analytics Framework for Conservation
          Planning Optimization, has been accepted to Environmental Modelling
          and Software. Congratulations Rui!
        </span>
      ),
    },
    {
      date: "Aug. 17, 2021",
      content: (
        <span>
          Tiankai Xie is accepted to present at the IEEE VIS Doctoral Colloquium.
          Congratulations!
        </span>
      ),
    },
    {
      date: "Jul. 15, 2021",
      content: (
        <span>
          Tiankai Xie's paper " FairRankVis: A Visual Analytics Framework for
          ExploringAlgorithmic Fairness in Graph Mining Models" is accepted to
          IEEE VIS 2021. Congrats Tiankai!
        </span>
      ),
    },
    {
      date: "Jul. 12, 2021",
      content: (
        <span>
          Rui Zhang successfully completed her PhD proposal examination.
          Congratulations Rui!
        </span>
      ),
    },
    {
      date: "Jun. 29, 2021",
      content: <span>Ross Maciejewski was promoted to Full Professor.</span>,
    },
    {
      date: "Jun. 28, 2021",
      content: (
        <span>
          Rui Zhang's paper "Exploring Geographic Hotspots Using Topological
          Data Analysis" was accepted to Transactions on GiS. Congratulations
          Rui!
        </span>
      ),
    },
    {
      date: "Apr. 29, 2021",
      content: (
        <span>
          Joint work ("A Broadband Integrated Time Series (BITS) for
          Longitudinal Analyses of the Digital Divide") led by Professor
          Elizabeth Mack at Michigan state has been accepted to PLoS One.
        </span>
      ),
    },
    {
      date: "Jan. 28, 2021",
      content: (
        <span>Professor Maciejewski is giving a keynote talk at VDA 2021.</span>
      ),
    },
  ],
};
