import React, { PureComponent } from "react";
import { Row, Col, Carousel } from "antd";

import { homeContent, carouselContent } from "../data/home";
import { hrefTargetAndRelHelper } from "../utils";
import "../styles/home.css";

export default class Home extends PureComponent {
  // constructor(props) {
  //     super(props);
  // }

  render() {
    return (
      <Row
        style={{
          marginTop: 24,
        }}
      >
        {/*<Row*/}
        {/*gutter={48}*/}
        {/*type="flex"*/}
        {/*justify="space-around"*/}
        {/*align="middle"*/}
        {/*style={{*/}
        {/*height: 800*/}
        {/*}}*/}
        {/*>*/}
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "middle",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 42,
              }}
            >
              {homeContent.labName}
            </h1>
            <p
              style={{
                fontSize: 18,
                textAlign: "justify",
              }}
            >
              {homeContent.labDescription}
            </p>
          </div>
        </Row>
        <Row
          style={{
            width: "100%",
            marginTop: 18,
          }}
        >
          <Col span={16} offset={4}>
            <Carousel
              autoplay
              style={{
                margin: "auto",
              }}
            >
              {carouselContent.map((slide, i) => (
                <Row key={i}>
                  <div
                    style={{
                      // width: 526,
                      // height: 514,
                      // backgroundImage: `url("${slide.image}")`,
                      // backgroundColor: "gray",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a
                      href={`/#/publication?nameKeyword=${slide.titleSearchKeyword}`}
                      rel="noopener noreferrer"
                    >
                      <img src={slide.image} alt="" style={{ height: 400 }} />
                    </a>
                    <h2
                      style={
                        {
                          // paddingLeft: 16,
                          // paddingRight: 16
                        }
                      }
                    >
                      {slide.title}
                    </h2>
                    <p
                      style={{
                        // paddingLeft: 16,
                        // paddingRight: 16
                        marginBottom: 16,
                      }}
                    >
                      {slide.description}
                    </p>
                  </div>
                </Row>
              ))}
            </Carousel>
          </Col>
        </Row>
        {/*</Row>*/}
        {/*<Row*/}
        {/*gutter={48}*/}
        {/*type="flex"*/}
        {/*justify="space-around"*/}
        {/*align="middle"*/}
        {/*>*/}
        {/*<Col span={12}>*/}
        {/*<h1*/}
        {/*style={{*/}
        {/*fontWeight: 'bold',*/}
        {/*fontSize: 40*/}
        {/*}}*/}
        {/*>*/}
        {/*Contact*/}
        {/*</h1>*/}
        {/*</Col>*/}
        {/*<Col span={12}>*/}
        {/*<div*/}
        {/*style={{*/}
        {/*// fontSize: 20,*/}
        {/*textAlign: 'justify'*/}
        {/*}}*/}
        {/*>*/}
        {/*<h2>*/}
        {/*<span style={{fontWeight: 'bold'}}>Ross Maciejewski</span> (Principal Investigator)*/}
        {/*</h2>*/}
        {/*<div>*/}
        {/*<p>BYENG 344, School of Computing, Informatics and Decision Systems Engineering, Tempe, AZ 85287-9709</p>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</Col>*/}
        {/*</Row>*/}
      </Row>
    );
  }
}
