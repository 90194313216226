import React from 'react';

import {hrefTargetAndRelHelper} from '../../utils';

// images
import rossEducation from '../../images/project/foodenergywaterva/AWEW-Ross.jpg';
import impactSoftware from '../../images/project/foodenergywaterva/software/impact.jpg';
import sankeySoftware from '../../images/project/foodenergywaterva/software/sankey.png';


export const FoodEnergyWaterVAData = {
    idForPubBrowser: 1,
    lastUpdated: 'September 13, 2022',
    key: 'food-energy-water-va',
    shortTitle: 'Food-Energy-Water VA',
    title: 'Flexible Model Compositions and Visual Representations for Planning and Policy Decisions for the Food-Energy-Water Nexus',
    tabs: [{
        tabName: 'Abstract',
        type: 'normal',
        content: <div>
            <p>
                In recent years, there has been an increasing focus on the processes and interactions among food,
                energy,
                and water systems, or the so-called food-energy-water (FEW) nexus, and the resulting implications for
                sustainability, resilience, and security. Food represents agricultural trade and consumption and is a
                critical component of a region's economy. Energy is required to supply and treat water for agriculture,
                municipal, and industrial uses, as well as to mechanize agricultural activities. Water is used for human
                and
                industrial consumption, crop irrigation, and energy production. While the multifaceted interactions
                between
                food, energy and water are often framed as threats or stresses of one system upon the others, basic
                understanding of the feedback dynamics is necessary for identifying synergies and potential
                efficiencies.
                Yet, despite known interrelationships at the FEW nexus, policy, planning, and management decisions for
                food,
                energy, and water are typically made in isolation from one another without full consideration of the
                tradeoffs between sectors. This is primarily because of the complexity of each isolated system, which
                makes
                understanding the interconnections between systems difficult to identify let alone assess in an
                integrated
                manner. Despite this challenge, knowledge of the linkages, synergies, and conflicts in the FEW nexus is
                desperately needed to provide evidence-based decision-making for policies in each sector that are most
                likely to produce positive effects in the other sectors. To achieve such integration, decision makers
                need
                to incorporate information about national, regional, and local scale impacts of food-energy-water
                interactions into the development of robust policy decisions across a range of future conditions. In
                this
                way, policies can be developed that can insure resilience of the FEW nexus under variable climate
                conditions
                and futures to help ensure public well-being and sustainable growth.
            </p>
            <p>
                The overarching goal of this research is to develop basic interdisciplinary scientific understanding of
                food, energy, and water system dynamics to inform an integrated modeling, visualization, and decision
                support infrastructure for comprehensive FEW systems. This will require the development of (i) a
                multi-resolution integrated modeling framework that explicitly captures the feedbacks among food, energy
                and
                water sectors; and (ii) a visualization infrastructure that enables model composition and reveals
                cascading
                effects across the three FEW areas as well as their multivariate spatiotemporal uncertainties. Such an
                infrastructure needs to be easy to use with a seamless integration of analytical and visual tools,
                adaptable
                to new algorithms, and should empower individuals to gain knowledge about their data and the associated
                uncertainty. To test the proposed framework, the focus of this project will be on a use case in Arizona,
                the
                Phoenix Active Management Area (AMA). The Phoenix AMA is a compelling case study for exploring the FEW
                nexus
                at sub-regional scale and is ideal to design and test an integrated modeling, visualization, and
                decision
                support framework to interactively explore the interconnections of the FEW systems and support effective
                resource management and human decision making. While the focus is on a specific study region, the
                long-term
                outcome of this proposal is to create a flexible and multiscale visualization and decision support
                infrastructure that could be easily adapted to other locations. Broader impacts of the research program
                include: 1) infrastructure for policy, research and education in the form of an anticipatory modeling
                framework; 2) expanding research in decision making under uncertainty for sustainability within the
                context
                of multi-directional linkages to FEW nexus, and; 3) enhanced partnerships between computer science,
                hydrology, agriculture, economics and sustainability to encourage the development of infrastructure that
                enables model coupling, anticipatory analysis and stakeholder engagement. Additional information can be
                found at the project website (<a href="http://vader.lab.asu.edu/" {...hrefTargetAndRelHelper}>http://vader.lab.asu.edu/</a>)
                including open
                source software, course learning modules and other material.
            </p>
        </div>

    }, {
        tabName: 'Publications',
        type: 'publications'
    }, {
        tabName: 'Education',
        type: 'normal',
        content: <div>
            <p>A key component of this project is stakeholder engagement which can be facilitated through education and
                outreach. While our team has focused on developing novel models and analysis methods, we have also
                explored
                how to integrate knowledge products in a manner that can support interdisciplinary research. One key
                educational outreach portion of this work has been the team's participation in the Advanced Water
                Education
                Workshop that took place from July 9 - July 10, 2018. Partners from Arizona State University and the
                University of Arizona host a workshop for water educators each summer. This summer, members of this
                project
                team led several discussions to promote Food-Energy-Water Nexus Education.</p>
            <p>
                <img
                    src={rossEducation}
                    alt=""
                    style={{width: '100%'}}
                />
            </p>
        </div>
    }, {
        tabName: 'Software',
        type: 'software',
        software: [{
            title: 'The Impact of Spatial Scales on the Inter-Comparison of Climate Scenarios',
            description: 'Scenario analysis has been widely applied in climate science to understand the impact of climate change on the future human environment. Inter-comparison and similarity analysis of different climate scenarios based on multiple simulation runs remains challenging because of the inherently `black box\' structure in terms of modeling the complex interactions between climate change and human systems. Though spatial heterogeneity plays a key role in modeling climate and human systems, little research has been performed to understand the impact of spatial variations and scales on similarity analysis of climate scenarios. To address this issue, we have developed a geovisual analytics framework to allow users to perform similarity analysis of climate scenarios from the Global Change Assessment Model (GCAM) using a hierarchical clustering approach. However, since hierarchical clustering provides only a single variable output to give a comparison among scenarios, regional differences within scenarios may be obscured. As such, the proposed visual analytics system utilizes coordinated multiple views to enable analysis across varying geographic scales through linked brushing. Our results demonstrate how the impact of spatial variations and scales on similarity analysis of climate scenarios varies at world, continental, and country scales.',
            image: impactSoftware,
            publication: []
        }, {
            title: 'Exploring the Design Space of Sankey Diagrams for the Food-Energy-Water Nexus',
            description: <p>In this work, we define a set of design requirements relating to Sankey diagrams for supporting Food-Energy-Water (FEW) nexus understanding, and propose the NEST diagram design, a visualization design that incorporates a number of characteristics from Sankey diagrams, treemaps, and graphs, to improve readability and minimize the negative impact of edge crossings common in traditional Sankey diagrams.</p>,
            image: sankeySoftware,
            publication: []
        }]
    }, {
        tabName: 'Personnel',
        type: 'personnel',
        filter: 'food-energy-water-va'
    }]
};