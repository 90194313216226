import React from 'react';


export const NEWS2019 = {
    name: '2019',
    news: [{
        date: 'Dec. 23, 2019',
        content: <span>Our proposal to NSF - "FAI: Towards a Computational Foundation for Fair Network Learning" by Hanghang Tong (UIUC), My T. Thai (University of Florida), and Ross Maciejewski, was awarded!</span>
    }, {
        date: 'Oct. 17, 2019',
        content: <span>Joint work with Hanghang Tong's group, "Origin: Non-Rigid Network Alignment," has been accepted to the IEEE International Conference on Big Data. Congrats Si Zhang!</span>
    }, {
        date: 'Oct. 9, 2019',
        content: <span>Professor Maciejewski was appointed to the IEEE Transactions on Visualization and Computer Graphics Editorial Board as a new Associate Editor.</span>
    }, {
        date: 'Oct. 2, 2019',
        content: <span>The extended version of our Graph Drawing 2018 paper was accepted to IEEE TVCG - Same Stats, Different Graphs: Exploring the Space of Graphs in Terms of Graph Properties. Congrats to our partners at University of Arizona!</span>
    }, {
        date: 'Sept. 16, 2019',
        content: 'Joint work with Giuseppe Mascaro\'s lab from our Food-Energy-Water nexus work has been accepted for publication. A Metropolitan Scale Water Management Analysis of the Food-Energy-Water Nexus will appear in Science of the Total Environment. Congratulations Xin!'
    }, {
        date: 'Sept. 12, 2019',
        content: <span>A survey - Graph Convolutional Networks: A Comprehensive Review - with Hanghang Tong's Data Lab has been accepted to Computational Social Networks. Congratulations Si!</span>
    }, {
        date: 'Sept 6, 2019',
        content: <span>Joint work with Giuseppe Mascaro's lab from our Food-Energy-Water nexus work has been accepted for publication. A Metropolitan Scale Analysis of the Impacts of Future Electricity Mix Alternatives on the Water-Energy Nexus will appear in Applied Energy. Congratulations Adil!</span>
    }, {
        date: 'July 15, 2019',
        content: <span>Hong Wang successfully defended his PhD's Thesis "Visual Analytics Methodologies in Causality Analysis". Congratulations Hong!</span>
    }, {
        date: 'July 11, 2019',
        content: <span>Rui Zhang has been accepted to present at the IEEE VIS Doctoral Colloquium in October. Congratulations Rui!</span>
    }, {
        date: 'July 11, 2019',
        content: <span>Benjamin Clayton successfully defended his Masters Thesis, "The Impact of Graph Layouts on the Perception of Graph Properties." Congratulations Ben!</span>
    }, {
        date: 'July 8, 2019',
        content: <span>Our work on "Explaining Vulnerabilities to Adversarial Machine Learning through Visual Analytics" was accepted to IEEE VAST 2019. Congratulations Yuxin!</span>
    }, {
        date: 'July 2, 2019	',
        content: <span>Our work on "Exploring the Design Space of Sankey Diagrams for the Food-Energy-Water Nexus" was accepted to IEEE CG&A. Congratulations Brandon!</span>
    }, {
        date: 'June 4, 2019	',
        content: <span>Our joint work on "An Interactive Visual Analytics System for Exploring, Monitoring, and Forecasting Road Traffic Congestion (<a
            href="http://vader.lab.asu.edu/publications/papers/2019/TrafficAI.pdf">paper link</a>)" was accepted to IEEE TVCG. Congratulations to Sungahn Ko and the <a
            href="http://ivaderlab.unist.ac.kr">iVADER lab</a>!</span>
    }, {
        date: 'May 24, 2019	',
        content: <span>Work with the DataLab@ASU was just published! Location Prediction for Tweets, by Chieh-Yang Huang, Hanghang Tong, Jingrui He, Ross Maciejewski, published in Frontiers in Big Data, section Data Mining and Management.</span>
    }, {
        date: 'Feb. 15, 2019',
        content: <span>	Brandon Mathis successfully defended his Master's Thesis "Visualizing Network Structures in the Food, Energy, and Water Nexus". Congratulations Brandon!</span>
    }, {
        date: 'Jan. 22, 2019',
        content: <span>	New joint work from the Data Lab@ASU "Multilevel Network Alignment" by Si Zhang and Hanghang Tong was accepted to WWW’19. Congratulations Si!</span>
    }, {
        date: 'Jan. 16, 2019',
        content: <span>	Christina Gillman's paper, Hierarchical Image Semantics Using Probabilistic Path Propagations for Biomedical Research, was accepted to IEEE Computer Graphics & Applications. Congrats Christina!</span>
    }, {
        date: 'Jan. 11, 2019',
        content: <span>	A new <a
            href="https://asunow.asu.edu/20190110-predicting-future-better-data-visualization">ASU Now article</a> about Dr. Maciejewski's NSF Career Award research has been published.</span>
    }
    ]
};