import React from 'react';
import {hrefTargetAndRelHelper} from '../../utils';

import boxOffice from '../../images/project/xai/DM2Simi3.png';
import eventCueing from '../../images/project/xai/Event_Cueing.png';
import pva from '../../images/project/xai/PVA_Browser.png';
import adversarialVA from '../../images/project/xai/adversarial-va.png';
import transfer from '../../images/project/xai/transfer.png';
import auditingSensitivity from "../../images/project/graphanalytics/auditing-sensitivity.jpg";
import llp from '../../images/project/xai/llp.png';


export const XAI = {
    idForPubBrowser: 3,
    lastUpdated: null,
    key: 'xai',
    shortTitle: 'Explainable AI',
    title: 'Explainable AI',
    tabs: [{
        tabName: 'Abstract',
        type: 'normal',
        content: <div>
            <p>
                With the large scale deployment of machine learning models, there is an ever increasing need to explain
                the inner workings of these models to not only the model developers, but also to policy makers and the
                general public at large. Many of the current machine learning models are considered to be black boxes
                with complex structures and little to no interpretability of the internal mechanism. For non-expert
                users, the predictions provided by models are not able to effectively support the reasoning of how and
                why the decisions are made inside the black boxes, resulting in a lack of trust in the underlying
                algorithms for human users as well as unex- plainable odd behaviors from models. In Explainable AI
                (XAI), model comprehensibility is a major hurdle in the effective integration of machine-human
                prediction. Currently, the VADER lab is researching how a human-in-the-loop (HIL) analysis approach can
                enhance the understanding of predictive models and establish a more comfortable and effective
                relationship between humans and machines Predictive visual analytics methods from our lab have been
                applied in a variety of domains ranging from healthcare, intelligence analysis, and emergency crisis
                management. Ideally, by opening the black-box of data mining and machine learning algorithms, visual
                analytics can help users understand the reasoning behind prediction outcomes thereby improving model
                comprehensibility. Along with opening the black-box, we are actively exploring research methods to
                support human-machine interactions not only to improve model understanding, but also to enable user
                knowledge injection into the system. We see this intersection of human-machine analysis as a critical
                stage in the visual analytics pipeline.
            </p>
            <p style={{textAlign: 'center'}}>
                <img
                    src={adversarialVA}
                    alt="Adversarial VA"
                    style={{maxWidth: '70%'}}
                />
            </p>
        </div>
    }, {
        tabName: 'Software',
        type: 'software',
        software: [{
            title: 'Auditing the Sensitivity of Graph-based Ranking with Visual Analytics',
            description: <p>Graph mining plays a pivotal role across a number of disciplines, and a variety of
                algorithms have been developed to answer who/what type questions. For example, what items shall we
                recommend to a given user on an e-commerce platform? The answers to such questions are typically
                returned in the form of a ranked list, and graph-based ranking methods are widely used in industrial
                information retrieval settings. However, these ranking algorithms have a variety of sensitivities, and
                even small changes in rank can lead to vast reductions in product sales and page hits. As such, there is
                a need for tools and methods that can help model developers and analysts explore the sensitivities of
                graph ranking algorithms with respect to perturbations within the graph structure. In this paper, we
                present a visual analytics framework for explaining and exploring the sensitivity of
                any graph-based ranking algorithm by performing perturbation-based what-if analysis. We
                demonstrate our framework through three case studies inspecting the sensitivity of two classic
                graph-based ranking algorithms (PageRank and HITS) as applied to rankings in political news media and
                social networks.</p>,
            image: auditingSensitivity,
            publication: [<p>Tiankai Xie, Yuxin Ma, Hanghang Tong, My T. Thai, Ross Maciejewski. "Auditing the
                Sensitivity of Graph-based Ranking with Visual Analytics." IEEE Transactions on
                Visualization and Computer Graphics (Proc. VAST 2020). (To appear) <a
                    href="https://github.com/VADERASU/auditing-sensitivity-graph-ranking" {...hrefTargetAndRelHelper}>Github</a>
            </p>]
        }, {
            title: 'Visual Analysis of Class Separations with Locally Linear Segments',
            description: <p>High-dimensional labeled data widely exists in many real-world applications such as
                classification and clustering. One main task in analyzing such datasets is to explore class separations
                and class boundaries derived from machine learning models. Dimension reduction techniques are commonly
                applied to support analysts in exploring the underlying decision boundary structures by depicting a
                low-dimensional representation of the data distributions from multiple classes. However, such
                projection-based analyses are limited due to their lack of ability to show separations in complex
                non-linear decision boundary structures and can suffer from heavy distortion and low interpretability.
                To overcome these issues of separability and interpretability, we propose a visual analysis approach
                that utilizes the power of explainability from linear projections to support analysts when exploring
                non-linear separation structures. Our approach is to extract a set of locally linear segments that
                approximate the original non-linear separations. Unlike traditional projection-based analysis where the
                data instances are mapped to a single scatterplot, our approach supports the exploration of complex
                class separations through multiple local projection results. We conduct case studies on two labeled
                datasets to demonstrate the effectiveness of our approach.</p>,
            image: llp,
            publication: [<p>Yuxin Ma, Ross Maciejewski. "Visual Analysis of Class Separations with Locally Linear
                Segments." IEEE Transactions on Visualization and Computer Graphics. 2020. <a
                    href="https://github.com/wintericie/visual-analysis-class-boundary" {...hrefTargetAndRelHelper}>Github</a>
            </p>]
        }, {
            title: 'A Visual Analytics Framework for Explaining and Diagnosing Transfer Learning Processes',
            description: <p>Many statistical learning models hold an assumption that the training data and the future
                unlabeled data are drawn from the same distribution. However, this assumption is difficult to fulfill in
                real-world scenarios and creates barriers in reusing existing labels from similar application domains.
                Transfer Learning is intended to relax this assumption by modeling relationships between domains, and is
                often applied in deep learning applications to reduce the demand for labeled data and training time.
                Despite recent advances in exploring deep learning models with visual analytics tools, little work has
                explored the issue of explaining and diagnosing the knowledge transfer process between deep learning
                models. In this paper, we present a visual analytics framework for the multi-level exploration of the
                transfer learning processes when training deep neural networks. Our framework establishes a multi-aspect
                design to explain how the learned knowledge from the existing model is transferred into the new learning
                task when training deep neural networks. Based on a comprehensive requirement and task analysis, we
                employ descriptive visualization with performance measures and detailed inspections of model behaviors
                from the statistical, instance, feature, and model structure levels. We demonstrate our framework
                through two case studies on image classification by fine-tuning AlexNets to illustrate how analysts can
                utilize our framework.</p>,
            image: transfer,
            publication: [<p>Yuxin Ma, Arlen Fan, Jingrui He, Arun Reddy Nelakurthi, Ross Maciejewski. "A Visual
                Analytics Framework for Explaining and Diagnosing Transfer Learning Processes." IEEE Transactions on
                Visualization and Computer Graphics (Proc. VAST 2020). (To appear) <a
                    href="https://github.com/VADERASU/visual-analytics-for-deep-transfer-learning" {...hrefTargetAndRelHelper}>Github</a>
            </p>]
        }, {
            title: 'Explaining Vulnerabilities to Adversarial Machine Learning through Visual Analytics',
            description: <p>Machine learning models are currently being deployed in a variety of real-world applications
                where model predictions are used to make decisions about healthcare, bank loans, and numerous other
                critical tasks. As the deployment of artificial intelligence technologies becomes ubiquitous, it is
                unsurprising that adversaries have begun developing methods to manipulate machine learning models to
                their advantage. While the visual analytics community has developed methods for opening the black box of
                machine learning models, little work has focused on helping the user understand their model
                vulnerabilities in the context of adversarial attacks. In this paper, we present a visual analytics
                framework for explaining and exploring model vulnerabilities to adversarial attacks. Our framework
                employs a multi-faceted visualization scheme designed to support the analysis of data poisoning attacks
                from the perspective of models, data instances, features, and local structures. We demonstrate our
                framework through two case studies on binary classifiers and illustrate model vulnerabilities with
                respect to varying attack strategies.</p>,
            image: adversarialVA,
            publication: [<p>Yuxin Ma, Tiankai Xie, Jundong Li, Ross Maciejewski. "Explaining Vulnerabilities to
                Adversarial Machine Learning through Visual Analytics." IEEE Transactions on Visualization and Computer
                Graphics (Proc. VAST 2019). (To appear) <a
                    href="https://github.com/VADERASU/visual-analytics-adversarial-attacks" {...hrefTargetAndRelHelper}>Github</a>
            </p>]
        }, {
            title: 'Movie Box Office Prediction',
            description: <p>
                In 2013, VAST Contest had a mini-challenge of predicting up-coming movie's opening weekend gross given
                two week's movie related tweets prior to the release date as well as bitly links. Participating this
                contest, we started to collect tweets through keyword searching for movies released since 2013, and
                developed a set of visual analytics tools for social media data analysis, business intelligent
                analystic, and predictive analytics.
            </p>,
            image: boxOffice,
            publication: []
        }, {
            title: 'Event Cueing for Media Discourse Analysis',
            description: <p>Online news, microblogs and other media documents all contain valuable insight regarding
                events and responses to events. Underlying these documents is the concept of framing, a process in which
                communicators act (consciously or unconsciously) to construct a point of view that encourages facts to
                be interpreted by others in a particular manner. As media discourse evolves, how topics and documents
                are framed can undergo change, shifting the discussion to different viewpoints or rhetoric. What causes
                these shifts can be difficult to determine directly; however, by linking secondary datasets and enabling
                visual exploration, we can enhance the hypothesis generation process. In this paper, we present a visual
                analytics framework for event cueing using media data. As discourse develops over time, our framework
                applies a time series intervention model which tests to see if the level of framing is different before
                or after a given date. If the model indicates that the times before and after are statistically
                significantly different, this cues an analyst to explore related datasets to help enhance their
                understanding of what (if any) events may have triggered these changes in discourse. Our framework
                consists of entity extraction and sentiment analysis as lenses for data exploration and uses two
                different models for intervention analysis. To demonstrate the usage of our framework, we present a case
                study on exploring potential relationships between climate change framing and conflicts in Africa. </p>,
            image: eventCueing,
            publication: []
        }, {
            title: 'The State-of-the-Art in Predictive Visual Analytics',
            description: <p>
                Predictive analytics embraces an extensive range of techniques including statistical modeling, machine
                learning, and data mining and is applied in business intelligence, public health, disaster management
                and response, and many other fields. To date, visualization has been broadly used to support tasks in
                the predictive analytics pipeline. Primary uses have been in data cleaning, exploratory analysis, and
                diagnostics. For example, scatterplots and bar charts are used to illustrate class distributions and
                responses. More recently, extensive visual analytics systems for feature selection, incremental
                learning, and various prediction tasks have been proposed to support the growing use of complex models,
                agent-specific optimization, and comprehensive model comparison and result exploration. Such work is
                being driven by advances in interactive machine learning and the desire of end-users to understand and
                engage with the modeling process. In this state-of-the-art report, we catalogue recent advances in the
                visualization community for supporting predictive analytics. First, we define the scope of predictive
                analytics discussed in this article and describe how visual analytics can support predictive analytics
                tasks in a predictive visual analytics (PVA) pipeline. We then survey the literature and categorize the
                research with respect to the proposed PVA pipeline. Systems and techniques are evaluated in terms of
                their supported interactions, and interactions specific to predictive analytics are discussed. We end
                this report with a discussion of challenges and opportunities for future research in predictive visual
                analytics.
            </p>,
            image: pva,
            publication: []
        }]
    }, {
        tabName: 'Publications',
        type: 'publications'
    }
        // , {
        //     tabName: 'Personnel',
        //     type: 'personnel',
        //     filter: 'xai'
        // }
    ]
}