import React from 'react';

export const NEWS2012 = {
    name: '2012',
    news: [
        {
            "date": "Dec 20, 2012",
            "content": "Graduate student Jingxian Mao successfully defended his Master's Thesis Multimodal Data Fusion As a Predictior of Missing Information in Social Networks. Congratulations Jingxian!"
        },
        {
            "date": "Dec 15, 2012",
            "content": "Undergraduate student Michael Steptoe is accepted to the Fulton Undergraduate Research Initiative (FURI) program for Spring 2013."
        },
        {
            "date": "Dec 15, 2012",
            "content": "Undergraduate student Jose Ibarra is accepted to the Fulton Undergraduate Research Initiative (FURI) program for Spring 2013."
        },
        {
            "date": "Oct, 2012",
            "content": "Professor Ross Maciejewski's paper Spatial Text Visualization Using Automatic Typographic Maps is accepted for publication in IEEE Transactions on Visualization and Computer Graphics."
        },
        {
            "date": "Sept, 2012",
            "content": "Professor Ross Maciejewski's paper Feature-driven Data Exploration for Volumetric Rendering is accepted for publication in IEEE Transactions on Visualization and Computer Graphics."
        },
        {
            "date": "June, 2012",
            "content": "Professor Ross Maciejewski's paper MarketAnalyzer: An Interactive Visual Analytics System for Analyzing Competitive Advantage Using Point of Sale Data is accepted for publication in Computer Graphics Forum."
        },
        {
            "date": "May 4, 2012",
            "content": "Undergraduate student A. J. Fairfield is accepted to the Fulton Undergraduate Research Initiative  (FURI) program for Summer and Fall 2012."
        },
        {
            "date": "May, 2012",
            "content": "Professor Ross Maciejewski's paper OmicsVis: An Interactive Tool for Visually Analyzing Metabolomics Data is accepted for publication in BioMed Central."
        }
    ]
}