import React from 'react';
import ctImage from '../../images/project/scivis/mr_ct.jpg';
import nestedGraph from '../../images/project/scivis/nestedgraph.png';


export const SciVis = {
    idForPubBrowser: 4,
    lastUpdated: null,
    key: 'scivis',
    shortTitle: 'Scientific Visualization',
    title: 'Scientific Visualization',
    tabs: [{
        tabName: 'Abstract',
        type: 'normal',
        content:
            <div>
                <p>
                    The detection, tracking and extraction of features has been a major focus of scientific
                    visualization.
                    For example, in surgical preparation, physicians will often utilize multimodal imaging scans to
                    capture
                    complementary information to improve diagnosis and to drive patient-specific treatment. These
                    imaging
                    scans may consist of data from magnetic resonance imaging (MRI), computed tomography (CT), or other
                    various sources. Here, the tracking, extraction and co-registration of features is critical for
                    diagnosis. Another example is computational fluid dynamics. Imagine simulating the injection of a
                    jet
                    into a static material. Here the scientists wants to explore, extract, and track the evolution of
                    features over space and time. Our research focuses on developing novel visual analytics methods for
                    feature detection, tracking and extraction over a variety of domain problems.
                </p>
                <p style={{textAlign: 'center'}}>
                    <img
                        src={ctImage}
                        alt="scivis"
                        style={{width: '80%'}}
                    />
                </p>
                <p style={{textAlign: 'center'}}>
                    <img
                        src={nestedGraph}
                        alt="scivis"
                        style={{width: '80%'}}
                    />
                </p>
            </div>
    }, {
        tabName: 'Publications',
        type: 'publications'
    }]
};