import React, { PureComponent } from "react";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Link,
} from "react-router-dom";
import { Row, Col, Layout, Menu } from "antd";

import vaderLogo from "./images/vader-logo.png";
import asuLogo from "./images/asu-logo.png";
import Home from "./components/Home";
import People from "./components/People";
import News from "./components/News";
import Publication from "./components/Publication";
import Project from "./components/Project";
import { projectMetadata } from "./data/project/projectmetadata";

import "./App.css";

const { Header, Content, Footer } = Layout;

const NO_JUMP_MENU_ITEM = ["caoe", "external:github", "external:youtube"];

export default class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activePage: "home",
      projectKeyList: projectMetadata.map((p) => p.key),
    };
  }

  handleActivaPageChange = (activePage) => {
    const { key } = activePage;

    if (NO_JUMP_MENU_ITEM.indexOf(key) === -1) {
      this.setState({ activePage: activePage.key });
    }
  };

  // getActivePageComponent = () => {
  //     const {activePage, projectKeyList} = this.state;
  //
  //     switch (this.state.activePage) {
  //         case 'home':
  //             return <Route path="/home/"><Home/></Route>;
  //         case 'people':
  //             return <Route path="/people/"><People/></Route>;
  //         case 'news':
  //             return <Route path="/news/"><News/></Route>;
  //         case 'publications':
  //             return <Route path="/publication/"><Publication/></Route>;
  //         default:
  //     }
  //
  //     if (projectKeyList.find(key => key === activePage) !== undefined) {
  //         return <Project projectKey={activePage}/>;
  //     } else {
  //         return <div>Not Found</div>;
  //     }
  // };

  render() {
    // const activePageComponent = this.getActivePageComponent();

    return (
      <HashRouter basename="/" noslash>
        <Layout
          style={{
            margin: "auto",
            maxWidth: 1200,
            backgroundColor: "white",
          }}
        >
          <Header
            style={{
              backgroundColor: "white",
            }}
          >
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    flowDirection: "left",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={vaderLogo}
                    alt="vader-logo"
                    style={{
                      height: 40,
                    }}
                  />
                  <img
                    src={asuLogo}
                    alt="asu-logo"
                    style={{
                      height: 40,
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <Row type="flex" justify="end">
                  <Menu
                    theme="light"
                    mode="horizontal"
                    defaultSelectedKeys={["1"]}
                    style={{ lineHeight: "64px" }}
                    onClick={this.handleActivaPageChange}
                  >
                    <Menu.Item key={"home"} className="homepage-menu">
                      <Link to="/">
                        <b>Home</b>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={"people"} className="homepage-menu">
                      <Link to="/people/">
                        <b>People</b>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key={"news"} className="homepage-menu">
                      <Link to="/news/">
                        <b>News</b>
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key={"publications"}
                      // disabled
                      className="homepage-menu"
                    >
                      <Link to="/publication/">
                        <b>Publications</b>
                      </Link>
                    </Menu.Item>
                    <Menu.SubMenu
                      title={<b>Projects</b>}
                      className="homepage-menu"
                    >
                      {projectMetadata.map((p) => (
                        <Menu.Item key={p.key}>
                          <Link to={`/project/${p.key}/`}>{p.shortTitle}</Link>
                        </Menu.Item>
                      ))}
                      <Menu.Item key="caoe">
                        <a
                          href="https://caoe.asu.edu/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {"Center for Accelerating Operational Efficiency"}
                        </a>
                      </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                      title={<b>External</b>}
                      className="homepage-menu"
                    >
                      <Menu.Item key="external:youtube">
                        <a
                          href="https://www.youtube.com/channel/UCv15qUcVF2NVUQ1o3T8m3UQ"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Youtube Channel
                        </a>
                      </Menu.Item>
                      <Menu.Item key="external:github">
                        <a
                          href="https://github.com/VADERASU"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Github
                        </a>
                      </Menu.Item>
                    </Menu.SubMenu>
                    {/*<Menu.Item key={6}>External</Menu.Item>*/}
                    {/*<Menu.Item key={7}>Github</Menu.Item>*/}
                  </Menu>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              backgroundColor: "white",
              paddingLeft: 50,
              paddingRight: 50,
            }}
          >
            {/*{activePageComponent}*/}
            <Route path="/" exact component={Home} />
            <Route path="/people/" exact component={People} />
            <Route path="/news/" exact component={News} />
            <Route path="/publication/" exact component={Publication} />
            {projectMetadata.map((p) => (
              <Route
                path={`/project/${p.key}/`}
                exact
                render={() => <Project projectKey={p.key} />}
              />
            ))}
          </Content>
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "white",
            }}
          >
            © 2024 VADER Lab, SCAI, ASU
          </Footer>
        </Layout>
      </HashRouter>
    );
  }
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
//
// export default App;
