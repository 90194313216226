import React, {PureComponent} from 'react';
import queryString from 'query-string';

import PubBrowser from './PubBrowser';

export default class Publication extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const query = queryString.parse(this.props.location.search);

        return (<PubBrowser {...query}/>);
    }
}