import React from 'react';


export const NEWS2017 = {
  name: '2017',
  news: [
    {date: 'Nov. 29, 2017',
      content: <span>New research led by Dave White and Leah Jones on the Nexus of Food-Energy-Water has been published in Sustainability (Stakeholder Analysis for the Food-Energy-Water Nexus in Phoenix, Arizona: Implications for Nexus Governance). Congratulations team!</span>
    }
    , {date: 'Nov. 13, 2017',
      content: <span>Our joint paper "A Visual Analytics Framework for Exploring Theme Park Dynamics" with the VIS group at Stuttgart was accepted to ACM Transactions on Intelligent Information Systems. Congratulations!</span>
    }
    , {
      date: 'Sept. 15, 2017',
      content: <span>Yafeng Lu was selected to present her research poster "Benefits and Bias of Human-in-the-Loop Predictive Visual Analytics" at the <a href="https://cra.org/ccc/">Computing Community Consortium (CCC)</a> <a href="https://cra.org/ccc/events/computing-research-addressing-national-priorities-societal-needs-2017/#overview">Symposium on Computing Research Addressing National Priorities and Societal Needs</a> in Washington, DC on October 23-24, 2017. Congratulations Yafeng!
      </span>
    }, {
      date: 'Sept. 11, 2017',
      content: <span>Yafeng Lu and Hong Wang's paper "A Visual Analytics Framework for Identifying Topic Drivers in Media" has been accepted for publication in IEEE TVCG. Congratulations Yafeng and Hong!
      </span>
    }, {
      date: 'Aug. 7, 2017	',
      content: <span>DHS selects ASU to lead the new Center of Excellence for Accelerating Operational Efficiency. <a href="https://www.dhs.gov/science-and-technology/news/2017/08/07/news-release-dhs-selects-arizona-state-university-lead-coe">Link</a>
      </span>
    }, {
      date: 'June 16, 2017',
      content: <span>The Nested Tracking Graphs paper authored by Jonas Lukasczyk was awarded Best Paper at EuroVis 2017. Congrats Jonas and all the authors!
      </span>
    }, {
      date: 'May 10, 2017	',
      content: <span>Our work on Entrepreneurship with Dr. Mack at Michigan State has been covered by City Lab: <a href="https://www.citylab.com/work/2017/05/visualizing-entrepreneurial-networks-via-twitter/518574/">Link</a>
      </span>
    }, {
      date: 'May 8, 2017',
      content: <span>VADER Lab graduates include Rolando Garcia, Yafeng Lu, Ryan Simmons, and Feng Wang, congratulations everyone!
      </span>
    }, {
      date: 'May 5, 2017',
      content: <span>Dr. Maciejewski was notified of his promotion to Associate Professor beginning in the 2017-2018 academic year.
      </span>
    }, {
      date: 'May 3, 2017',
      content: <span>Yafeng Lu's State-of-the-Art Report in Predictive Visual Analytics has been accepted to EuroVis 2017. Congratulations Yafeng, Rolando and Brett!
      </span>
    }, {
      date: 'Apr. 10, 2017',
      content: <span>Feng Wang successfully defended his PhD thesis: "Visual Analytics Methods for Exploring Geographically Networked Phenomena." Congratulations Feng!
      </span>
    }, {
      date: 'Apr. 5, 2017	',
      content: <span>Our joint work on visualizing the Global Change Assessment models with partners at PNNL (The Impact of Spatial Scales on the Inter-Comparison of Climate Scenarios) has been accepted for publication in IEEE CG&A. Congratulations team!
      </span>
    }, {
      date: 'Apr. 4, 2017	',
      content: <span>Yafeng Lu successfully defended her PhD thesis: "Methodologies in Predictive Visual Analytics." Congratulations Yafeng!
      </span>
    }, {
      date: 'Mar. 27, 2017',
      content: <span>Our joint paper with Arianne Middel on Sky View Factors from Synthetic Fisheye Photos for Thermal Comfort Routing - A Case Study in Phoenix, Arizona was published in Urban Planning: <a href="http://www.cogitatiopress.com/urbanplanning/article/view/855">Link</a>
      </span>
    }, {
      date: 'Mar. 19, 2017',
      content: <span>Our joint paper with TU Kaiserslautern, Nested Tracking Graphs, has been accepted to EuroVis. Congratulations Jonas!
      </span>
    }, {
      date: 'Mar. 17, 2017',
      content: <span>Alexandra Porter and Rolando Garcia were selected for the NSF Graduate Fellowship. Congratulations Alex and Rolando!
      </span>
    }, {
      date: 'Feb. 9, 2017	',
      content: <span>Our joint paper, "The Role of Teamwork in the Analysis of Big Data --- A Study of Visual Analytics and Box office Prediction," has been accepted into Big Data. Thanks to our partners in Dr. Nancy Cooke's Lab. Congratulations Verica and Yafeng!
      </span>
    }, {
      date: 'Jan. 24, 2017',
      content: <span>Our paper "Data Flow Analysis and Visualization for Spatiotemporal Statistical Data without Trajectory Information" was accepted to IEEE TVCG. Thanks to our partners from Dr. Yun Jang's lab at Sejong University!
      </span>
    }, {
      date: 'Jan. 19, 2017',
      content: <span>Yafeng Lu was selected to receive a Student Scholarship from the IEEE Phoenix Section for 2017 and will be presented the "Dieter Schroder" award plaque. Congratulations Yafeng!
      </span>
    }, {
      date: 'Jan. 4, 2017	',
      content: <span>ASU Inner Circle published an article on Alex and Rolando's recent awards: <a href="https://innercircle.engineering.asu.edu/2016/12/30/computer-science-research-talents-bring-undergrads-honors/">Link</a></span>}
  ]
};