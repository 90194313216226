import React from 'react';

export const NEWS2014 = {
    name: '2014',
    news: [
        {
            "date": "Dec. 30, 2014",
            "content": "Professor Maciejewski (along with co-inventors David Ebert, Ryan Hafen, Stephen Rudolph, and William S. Cleveland) received a US Patent for Forecasting hotspots using a predictive visual analytics approach, US 8,924,332 B2"
        },
        {
            "date": "Nov. 13, 2014",
            "content": "Yafeng Lu presented her work on Integrating Predictive Analytics and Social Media at the IEEE Visual Analytics Science and Technology Conference in Paris, France"
        },
        {
            "date": "Nov. 11, 2014",
            "content": "Professor Maciejewski (along with co-inventors David Ebert, Benjamin Tyner, Yun Jang, William S. Cleveland and Sandra Amass) received a US Patent for Animal Symptom Visual Analytics, US 8,882,664 B2"
        },
        {
            "date": "Nov. 9, 2014",
            "content": "Ross Maciejewski (along with Adam Perer, Enrico Bertini and Jimeng Sun) organized the IEEE VIS 2014 Workshop on Visualization for Predictive Analytics."
        },
        {
            "date": "Sep. 30, 2014",
            "content": "Professor Maciejewski (along with co-inventors David Ebert, Timothy Collins and Abish Malik) received a US Patent for the Visual Analytics Law Enforcement Technology Tool, US 8,849,728 B2"
        },
        {
            "date": "Aug. 1st, 2014",
            "content": "Ross Maciejewski was awarded a National Science Foundation CAREER award.  This prestigious NSF CAREER Award recognizes young engineers and scientists who demonstrate the potential to become research and education leaders in their field. The award will provide approximately $430,000 over five years."
        },
        {
            "date": "July 11, 2014",
            "content": "Yafeng Lu and Feng Wang's work with partners from the Stuttgart VIS group (Integrating Predictive Analytics and Social Media) was accepted to IEEE VAST.  Congratulations Yafeng and Feng!"
        },
        {
            "date": "July 11, 2014",
            "content": "Our joint work with Purdue (VACCINE), Proactive Spatiotemporal Resource Allocation and Predictive Visual Analytics for Community Policing and Law Enforcement, was accepted to IEEE VAST."
        },
        {
            "date": "June 18, 2014",
            "content": "ASU has announced a new initiative with the NGA in which the VADER lab will play a large roll: http://www.prweb.com/releases/2014/06/prweb11954265.htm"
        },
        {
            "date": "April 15, 2014",
            "content": "Daniel Martin was award a Fulton Undergraduate Research Internship for the 2014 - 2015 school year. Congratulations Daniel!"
        },
        {
            "date": "April 15, 2014",
            "content": "Alexandra Porter was award a Fulton Undergraduate Research Internship for Fall 2014.  Congratulations Alex!"
        },
        {
            "date": "April 14, 2014",
            "content": "Feng Wang and Jose Ibarra's short paper (What's In a Name? Data Linkage, Demography and Visual Analytics) was accepted to the Eurovis Workshop on Visual Analytics.  Congratulations Jose and Feng!"
        },
        {
            "date": "Mar. 21, 2014",
            "content": "Jose Ibarra successfully defended his Undergraduate Honor's Thesis.  Congratulations Jose!"
        },
        {
            "date": "Mar. 7, 2014",
            "content": "Prof. Maciejewski presented his work entitled \"Syndromic Surveillance with Emergency Department Chief Complaints\" at the ASU School of Public Affairs colloquium."
        },
        {
            "date": "Mar. 6, 2014",
            "content": "Jose Ibarra's work on geodemographics has been selected to appear at the Barrett Celebrating Honors Symposium on April 11th."
        },
        {
            "date": "Jan. 16, 2014",
            "content": "Prof. Maciejewski presented \"Enabling Prediction through the Use of Visual Analytics\" at the University of Arizona Computer Science Colloquium."
        }
    ]
}