import React from 'react';

export const NEWS2011 = {
    name: '2011',
    news: [
        {
            "date": "Dec 15, 2011",
            "content": "Undergraduate student A. J. Fairfield is accepted to the Fulton Undergraduate Research Initiative (FURI) program for Spring 2012."
        }
    ]
}