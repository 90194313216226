import React from 'react';

export const NEWS2016 = {
  name: '2016',
  news: [
    {
      date: 'Dec. 9, 2016	 ',
      content: <span> Alexandra Porter was selected as a finalist for the Computing Research Association's (CRA) Outstanding Undergraduate Research Award for PhD granting institutions, 2017, and Rolando Garcia was selected for Honorable Mention. Congratulations Alex and Rolando!
    </span>
    }, {
      date: 'Oct. 21, 2016	',
      content: <span>  Xing Liang successfully defended his MS thesis, "Visualizing Numerical Uncertainty in Climate Ensembles." Congratulations Xing!
    </span>
    }, {
      date: 'Oct. 21, 2016	',
      content: <span>  Our joint paper with colleagues in the School of Politics and Global Studies, "Fording Differences? Conditions Mitigating Water Insecurity in the Niger River Basin," was accepted for publication in Political Geography. Great job Steven Landis, Seyedbabk Rezaeedaryakenari, Yifan Zhang and everyone involved!
    </span>
    }, {
      date: 'Oct. 17, 2016	',
      content: <span>  ASU article on our INFEWs grant: http://fullcircle.asu.edu/research/societys-food-energy-water-challenges-require-interdisciplinary-approach/
    </span>
    }, {
      date: 'Sept. 2, 2016	',
      content: <span>  Our joint SciVis 2016 Contest submission, co-authored by our colleagues at Kaiserslautern and UC-Davis (Viscous Fingers: A Topological Visual Analytics Approach) received an honorable mention award. Congratulations Jonas!
    </span>
    }, {
      date: 'Aug. 15, 2016	',
      content: <span>  A team at ASU led by Dr. Ross Maciejewski (Co-PIs Giuseppe Mascaro, Rimjhim Aggarwal, Dave White and Hessam Sarjoughian) has been awarded an NSF grant for studying the food-energy-water nexus in Arizona "INFEWS/T2: Flexible Model Compositions and Visual Representations for Planning and Policy Decisions at the Sub-regional level of the food-energy-water nexus," National Science Foundation, $2.9M, 8/16 - 8/20.
    </span>
    }, {
      date: 'Aug. 11, 2016	',
      content: <span>  Marynia Kolak won a 2016 Concordium Award for Innovations with Data (http://www.academyhealth.org/Training/content.cfm?ItemNumber=19317). Congratulations Marynia!
    </span>
    }, {
      date: 'July 27, 2016	',
      content: <span>  Feng Wang's paper, "Analyzing Entrepreneurial Social Networks with Big Data" has been accepted to the Annals of the American Association of Geographers. Congrats to Feng and our collaborator Elizabeth Mack!
    </span>
    }, {
      date: 'July 21, 2016	',
      content: <span>  Yifan Zhang's paper on "Quantifying the Visual Impact of Classification Boundaries in Choropleth Maps" has been accepted to InfoVis 2016. Great job Yifan!
    </span>
    }, {
      date: 'June 21, 2016	',
      content: <span>  A special issue of Big Data guest edited by Ross Maciejewski, Adam Perer and Enrico Bertini was published (http://online.liebertpub.com/toc/big/4/2) and contains an editorial by Ross Maciejewski and Douglas Montgomery on "Visualization for Data Science: Adding Credibility, Legitimacy, and Saliency."
    </span>
    }, {
      date: 'June 7, 2016	 ',
      content: <span> Dr. Maciejewski presented the Keynote talk at the EnvirVis Workshop: Models, Simulations and Stakeholders: Embracing Visualization for Climate Analysis.
    </span>
    }, {
      date: 'May 3, 2016	  ',
      content: <span>  Dr. Maciejewski was awarded the 2015 Best Junior Faculty Researcher Award in the School of Computing, Informatics and Decision Systems Engineering at ASU.
    </span>
    }, {
      date: 'April 5, 2016	',
      content: <span>  Yifan Zhang successfully defended his PhD thesis "Visual Analytics for Spatiotemporal Cluster Analysis". Congratulations Yifan!
    </span>
    }, {
      date: 'March 23, 2016',
      content: <span>	Xing Liang's paper Visualizing Malaria Spread Under Climate Variability has been accepted to the EnvirVis Workshop at EuroVis 2016. Congratulations Xing and thanks to all the co-authors!
    </span>
    }, {
      date: 'March 19, 2016',
      content: <span>	Yifan Zhang's paper, "Visualizing the Impact of Geographical Variations on Multivariate Clustering" has been accepted to EuroVis 2016, congratulations Yifan!
    </span>
    }, {
      date: 'Feb. 14, 2016	',
      content: <span>  Our joint proposal with Dr. Shade Shutters, "Anticipating Effects of Agricultural Trade Networks on Water, Food, and Political Security" was granted funding by the Skoll Foundation.
      </span>
    }]
};