import React from "react";

import yixuan_24 from "../images/home/carousel/yixuanTVCG24.png";
import human_ai_teaming_jiayi from "../images/home/carousel/human_ai_teaming_jiayi.png";
import cancer_as_music2024 from "../images/home/carousel/cancer_as_music2024.png";
import text_map from "../images/home/carousel/text_map.png";

export const homeContent = {
  labName: "Welcome to VADER Lab",
  labDescription: (
    <p>
      The Visual Analytics and Data Exploration Research Lab at Arizona State
      University develops novel algorithms and methods for visualization and
      data science. Research in the lab focuses on foundations of spatial
      temporal analysis, interactive machine learning, and explainable AI.
      Ongoing research at the VADER Lab include exploring how decision support
      systems can enhance modeling, analysis and policy making at the
      food-energy-water nexus, how visual analytics can explain vulnerabilities
      in machine learning models, and applications of spatiotemporal analysis
      ranging from crime predictions to geo-referenced text analysis to economic
      geography.
    </p>
  ),
};

export const carouselContent = [
  {
    image: yixuan_24,
    title: "New Paper in IEEE TVCG",
    description:
      "Y. Wang, J. Zhao, J. Hong, R. G. Askin and R. Maciejewski, 'A Simulation-based Approach for Quantifying the Impact of Interactive Label Correction for Machine Learning,' in IEEE Transactions on Visualization and Computer Graphics",
    titleSearchKeyword: "A Simulation-based Approach for Quantifying the Impact of Interactive Label Correction for Machine Learning",
  },
  {
    image: text_map,
    title: "New Paper in ACM CHI 2024",
    description:
      "Fan, A., Lei, F., Mancenido, M., MacEachren, A., & Maciejewski, R, 'Understanding Reader Takeaways in Thematic Maps Under Varying Text, Detail, and Spatial Autocorrelation', In Proceedings ofthe ACM Conference on Human Factors in Computing Systems, (To appear)",
    titleSearchKeyword: "Understanding Reader Takeaways in Thematic Maps Under Varying Text, Detail, and Spatial Autocorrelation",
  },
  {
    image: cancer_as_music2024,
    title: "New Paper in ACM CHI 2024",
    description:
      "Hnatyshyn, R., Hong, J., Maciejewski, R., Norby, C., & Maley, C. C., 'Capturing Cancer as Music: Cancer Mechanisms Expressed through Musification', In Proceedings ofthe ACM Conference on Human Factors in Computing Systems, (To appear)",
    titleSearchKeyword: "Capturing Cancer as Music: Cancer Mechanisms Expressed through Musification",
  },
  {
    image: human_ai_teaming_jiayi,
    title: "New Paper in IEEE TVCG",
    description:
      "J. Hong, R. Maciejewski, A. Trubuil and T. Isenberg, 'Visualizing and Comparing Machine Learning Predictions to Improve Human-AI Teaming on the Example of Cell Lineage,' in IEEE Transactions on Visualization and Computer Graphics, vol. 30, no. 4, pp. 1956-1969, April 2024.",
    titleSearchKeyword: "Visualizing and Comparing Machine Learning Predictions to Improve Human-AI Teaming on the Example of Cell Lineage",
  }
];
