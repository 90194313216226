import React from 'react';

export const NEWS2015 = {
    name: '2015',
    news: [
        {
            "date": "Nov. 19, 2015",
            "content": "Our joint work with the ASU Data Mining and Machine Learning Lab (DMML), An Overview of Sentiment Analysis in Social Media and Its Applications in Disaster Relief, has been accepted for publication in the edited volume on Sentiment Analysis and Ontology Engineering: An Environment of Computational Intelligence.  Great job Ghazaleh Beigi!"
        },
        {
            "date": "Nov. 6, 2015",
            "content": "Zheng Chang successfully defended his Master's Thesis, \"Visual Analytics Tool for the Global Change Assessment Model\".  Congratulations Zheng!"
        },
        {
            "date": "Nov. 5, 2015",
            "content": "Rolando Garcia and Sagarika Kadambi were both awarded a Fulton Undergraduate Research Internship for Spring 2016. Congratulations Rolando and Sagarika!"
        },
        {
            "date": "Nov. 4, 2015",
            "content": "Wei Luo was elected as one of the Regular BOD members at the International Association of Chinese Professionals in Geographic Information Sciences (CPGIS) from 2015 to 2017.  Congratulations Wei!"
        },
        {
            "date": "Oct. 27, 2015",
            "content": "Feng Wang was awarded the Communications Impact Award for his submission to the IEEE-VGTC VPG 2015 Contest at IEEE VIS 2015.  Congratulations Feng!"
        },
        {
            "date": "Sept. 4, 2015",
            "content": "Alex Porter's FURI work was accepted to the IEEE VIS Workshop on Personal Visualization, co-authored by our colleagues at ASU's School of Nutrition and Health Promotion (A Survey of Personal Visualization in mHealth Apps).  Congratulations Alex!"
        },
        {
            "date": "Aug. 24, 2015",
            "content": "Our paper, co-authored by our colleagues at Kaiserslautern (Understanding Hotspots: A Topological Visual Analytics Approach) was accepted to ACM SIGSPATIAL.  Congratulations Jonas!"
        },
        {
            "date": "Aug. 12, 2015",
            "content": "The VADER/VIS group won the Award for Outstanding Comprehensive Submission in the VAST 2015 Grand Challenge."
        },
        {
            "date": "July 13, 2015",
            "content": "Our work (Exploring Evolving Media Discourse Through Event Cueing) was accepted to IEEE VAST!"
        },
        {
            "date": "June 17, 2015",
            "content": "Professor Maciejewski traveled to Bethune-Cookman University as one of the leaders of the VACCINE Center & Bethune-Cookman University 2015 Visual Analytics MSI Faculty Workshop."
        },
        {
            "date": "June 8, 2015",
            "content": "Arizona State University has been named a \"Center of Academic Excellence in Geospatial Sciences\" by the National Geospatial-Intelligence Agency and U.S. Geological Survey (USGS)."
        },
        {
            "date": "May 30, 2015",
            "content": "Joint work with ASU's DMML lab on Surpassing the Limit: Keyword Clustering to Improve Twitter Sample Coverage was accepted to the 26th ACM Conference on Hypertext and Social Media."
        },
        {
            "date": "May 1, 2015",
            "content": "Ross Maciejewski was awarded the 2014 Best Junior Faculty Researcher Award in the School of Computing, Informatics and Decision Systems Engineering at ASU."
        },
        {
            "date": "April 1, 2015",
            "content": "Jonas Lukasczyk and Xing Liang's work, \"A Collaborative Web-Based Environmental Data Visualization and Analysis Framework,\" was accepted to the EnvirVis workshop at EuroVis 2015.  Thanks to all the co-authors!"
        },
        {
            "date": "April 1, 2015",
            "content": "Ting Lei and Xing Liang's work, \"An Interactive Web-Based Geovisual Analytics Tool to Explore Water Scarcity in the Niger River Basin,\" was accepted to the EnvirVis workshop at EuroVis 2015.  Thanks to all the co-authors!"
        },
        {
            "date": "April 1, 2015",
            "content": "Wei Luo and Zheng Chang's work, \"Web Based Visualization of the Global Change Assessment Model,\" was accepted to the EnvirVis workshop at EuroVis 2015.  Thanks to all the co-authors!"
        },
        {
            "date": "Feb. 23, 2015",
            "content": "Yafeng Lu's paper \"Visualizing Social Media Sentiment in Disaster Scenarios\" was accepted to the 3rd International Workshop on Social Web for Disaster Management (SWDM'15) with co-authors Xia Hu, Feng Wang, Shamanth Kumar, Huan Liu and Ross Maciejewski."
        },
        {
            "date": "Feb. 19, 2015",
            "content": "Professor Maciejewski was elevated to the grade of IEEE Senior Member."
        }
    ]
}