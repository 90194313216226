import React from 'react';

export const NEWS2018 = {
  name: '2018',
  news: [
    {
      date: 'Dec. 13, 2018',
      content: <span>Zhaosong Huang's work as a visiting scholar at the VADER lab has been accepted for publication at IEEE TVCG. Congrats to our partners at the State Key Lab of CAD&CG, Zhejiang University and to co-author Yafeng Lu on their paper, "Exploring the Sensitivity of Choropleths under Attribute Uncertainty."</span>
    }
    , {
      date: 'Oct. 21, 2018',
      content: <span>	The VADER lab is presenting research in IEEE VIS 2018 from four different publications: A Visual Analytics Framework for Identifying Topic Drivers in Media, Impact of Spatial Scales on the Intercomparison of Climate Scenarios, The Name Profiler Toolkit, and A Visual Analytics Framework for Spatiotemporal Trade Analysis.</span>
    }
    , {
      date: 'July 12, 2018',
      content: <span>	An article about Rui Zhang's work (jointly with Dr. Jorge Sefair) with the Leonardo DiCaprio Foundation has been posted <a href="https://www.leonardodicaprio.org/global-security-initiative-predictive-science-for-conservation-planning/">here</a>.</span>
    }
    , {
      date: 'July 11, 2018',
      content: <span>	Hong Wang has been accepted to present at the IEEE VIS Doctoral Colloquium in October. Congratulations Hong!</span>
    }
    , {
      date: 'July 11, 2018',
      content: <span>	Hong Wang's paper on " A Visual Analytics Framework for Spatiotemporal Trade Network Analysis" has been accepted to IEEE VAST. Congrats Hong, Yafeng, and Michael!</span>
    }
    , {
      date: 'June 20, 2018',
      content: <span>	Prannoy Chandra Pydi Medini successfully defended his Master's Thesis, "A Spatial Decision Support System for Oil Spill Response and Recovery." Congratulations Prannoy!</span>
    }
    , {
      date: 'June 20, 2018',
      content: <span>	Utkarsh Soni successfully defended his Master's Thesis, "The Perception of Graph Properties in Graph Layouts." Congratulations Utkarsh!</span>
    }
    , {
      date: 'June 14, 2018',
      content: <span>	Our work with Ariane Middel "Sky View Factor footprints for urban climate modeling" has been published! You can find the paper <a href="https://www.sciencedirect.com/science/article/pii/S2212095518301883h">here</a>.</span>
    }
    , {
      date: 'June 6, 2018	',
      content: <span>Utkarsh Soni presented our work "The Perception of Graph Properties in Graph Layouts" at EuroVis 2018.</span>
    }
    , {
      date: 'Apr. 13, 2018',
      content: <span>Rui Zhang presented her work on Spatial Optimization for Conservation Planning at the 2018 CRA-W Grad Cohort for Women symposium.</span>
    }
    , {
      date: 'Mar. 21, 2018',
      content: <span>Our joint paper with Stephen Kobourov and Helen Purchase, "The Perception of Graph Properties in Graph Layouts," was accepted to EuroVis 2018. Congratulations Utkarsh and Yafeng!</span>
    }
    , {
      date: 'Mar. 8, 2018	',
      content: <span>The EasySVM paper by Yuxin Ma was selected for an honorable mention award from CVMJ of all papers published in 2017. Congrats Yuxin!</span>
    }
    , {
      date: 'Feb. 9, 2018	',
      content: <span>Jiawei Zhang's (one of our partners in the VACCINE lab at Purdue) paper, entitled "TopoText: Context-Preserving Semantic Exploration Across Multiple Spatial Scales" has been awarded an Honourable Mention Award for CHI 2018, which means that it is ranked among the top 5% of all submissions to the SIGCHI 2018 conference. Congrats Jiawei!</span>
    }
    , {
      date: 'Jan. 15, 2018',
      content: <span>New joint work with Purdue and Maryland visualization researchers, "TopoText: Context-Preserving Text Data Exploration Across Multiple Spatial Scale" was accepted to ACM CHI. Congratulations Jiawei!</span>
    }
  ]
};