import React from 'react';

export const NEWS2013 = {
    name: '2013',
    news: [
        {
            "date": "Dec. 10, 2013",
            "content": "Yafeng Lu was interviewed by Sonya Stinson for Diversity/Careers in Engineering & Information Technology magazine: CS and IT grad students tackle real-world challenges head on. Link"
        },
        {
            "date": "Dec. 3, 2013",
            "content": "Our joint work with Purdue (VACCINE), A Mobile Visual Analytics Approach for Law Enforcement Situation Awareness has been accepted to IEEE PacificVis 2014."
        },
        {
            "date": "Nov. 15, 2013",
            "content": "Undergraduate student Alexandra Porter is accepted to the Fulton Undergraduate Research Initiative (FURI) program for Spring 2014."
        },
        {
            "date": "Oct. 13, 2013",
            "content": "Yafeng Lu presented our Award Winning work on the VAST Box Office Challenge at IEEE VIS."
        },
        {
            "date": "Oct. 10, 2013",
            "content": "Adam Fairfield's paper  Volume curtaining: A focus+context effect for multimodal volume visualization  was accepted at the SPIE Medical Imaging 2014 Conference.  Congratulations A.J.!"
        },
        {
            "date": "Sept 6, 2013",
            "content": "Our joint work with ASU's  School of Geographical Sciences and Urban Planning,\n IVIID: A Visual Analytics Tool for the Spatio-Temporal Analysis of Industrial Composition, was accepted to the Journal of Geographical Systems. This tool was developed as part of Yifan Zhang's dissertation work. Congratulations Yifan!"
        },
        {
            "date": "Sept 1, 2013",
            "content": "Our joint work with Wei Chen's Visaul Analytics group at the State Key Lab of CAD&CG at Zhejiang University,\n A Novel Visual Analysis Approach for Clustering Large-Scale Social Data, was accepted by BigDataVis 2013."
        },
        {
            "date": "Aug 29, 2013",
            "content": "Yafeng Lu and Feng Wang's work on the VAST Box Office Challenge was selected for an award: Excellent Visual Analysis of Structured and Unstructured Data .  Congratulations Yafeng and Feng!"
        },
        {
            "date": "July, 2013",
            "content": "Yifan Zhang's paper  Exploring Geo-Genealogy Using Internet Search Histories  is accepted for publication in the  Journal of Maps."
        },
        {
            "date": "July, 2013",
            "content": "Fred Morstatter from the DMML group at ASU presented our joint work on TweetXplorer as a KDD Demo."
        },
        {
            "date": "Jun, 2013",
            "content": "Our new geovisualization technique, Bristle Maps: A Multivariate Abstraction Technique for Geovisualization, is accepted for publication in IEEE Transactions on Visualization and Computer Graphics."
        },
        {
            "date": "May 6, 2013",
            "content": "Undergraduate student A.J. Fairfield selected as Honorable Mention in the Computing Research Association's (CRA) Outstanding Undergraduate Researcher Award 2013. Congratulations A.J.!"
        },
        {
            "date": "Jan, 2013",
            "content": "Professor Ross Maciejewski's paper Abstracting Attribute Space for Transfer Function Exploration and Design is accepted for publication in IEEE Transactions on Visualization and Computer Graphics."
        },
        {
            "date": "Jan, 2013",
            "content": "Professor Ross Maciejewski's paper Automated Box-Cox Transformations for Improved Visual Encoding is accepted for publication in IEEE Transactions on Visualization and Computer Graphics."
        }
    ]
}