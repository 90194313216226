import React from 'react';
import graphDemo from '../../images/project/graphanalytics/graphanalytics_table1.png';
import auditingSensitivity from '../../images/project/graphanalytics/auditing-sensitivity.jpg';
import {hrefTargetAndRelHelper} from "../../utils";


export const GraphAnalytics = {
    idForPubBrowser: 10,
    lastUpdated: null,
    key: 'graph-analytics',
    shortTitle: 'Graph Analytics',
    title: 'Graph Properties, Layouts, and Perception',
    tabs: [{
        tabName: 'Abstract',
        type: 'normal',
        content: <div>
            <p>
                In many applications, the relationships between objects can be represented as graph structures, and
                graph analysis methods have proved to be an effective way of understanding topological properties and
                complex relations in real world problems. To conduct such analyses, graph-based machine learning and
                graph visualization are two common methods used to extract meaningful features via automated algorithms
                or human-centered analysis. As an increasing amount of graph learning models are being developed,
                interpretable graph learning models are needed to generate explainable results for machine learning
                experts and domain users to help in identifying issues of fairness, bias, and vulnerability in the
                machine learning models. In the VADER lab, we focus on establishing interpretations for graph learning
                models with human-in-the-loop (HIL) analysis approaches to enhance the understanding of both graph data
                and the learning models to support experts in the sensemaking process.
            </p>
            <p style={{textAlign: 'center'}}>
                <img
                    src={graphDemo}
                    alt="graphdemo"
                    style={{maxWidth: '80%'}}
                />
            </p>
        </div>
    }, {
        tabName: 'Publications',
        type: 'publications'
    }, {
        tabName: 'Software',
        type: 'software',
        software: [{
            title: 'Auditing the Sensitivity of Graph-based Ranking with Visual Analytics',
            description: <p>Graph mining plays a pivotal role across a number of disciplines, and a variety of
                algorithms have been developed to answer who/what type questions. For example, what items shall we
                recommend to a given user on an e-commerce platform? The answers to such questions are typically
                returned in the form of a ranked list, and graph-based ranking methods are widely used in industrial
                information retrieval settings. However, these ranking algorithms have a variety of sensitivities, and
                even small changes in rank can lead to vast reductions in product sales and page hits. As such, there is
                a need for tools and methods that can help model developers and analysts explore the sensitivities of
                graph ranking algorithms with respect to perturbations within the graph structure. In this paper, we
                present a visual analytics framework for explaining and exploring the sensitivity of
                any graph-based ranking algorithm by performing perturbation-based what-if analysis. We
                demonstrate our framework through three case studies inspecting the sensitivity of two classic
                graph-based ranking algorithms (PageRank and HITS) as applied to rankings in political news media and
                social networks.</p>,
            image: auditingSensitivity,
            publication: [<p>Tiankai Xie, Yuxin Ma, Hanghang Tong, My T. Thai, Ross Maciejewski. "Auditing the
                Sensitivity of Graph-based Ranking with Visual Analytics." IEEE Transactions on
                Visualization and Computer Graphics (Proc. VAST 2020). (To appear) <a
                    href="https://github.com/VADERASU/auditing-sensitivity-graph-ranking" {...hrefTargetAndRelHelper}>Github</a>
            </p>]
        }]
    }, {
        tabName: 'Datasets',
        type: 'normal',
        content: <div>
            <p>
                For graph density, we have generated 4,950 graphs. There are 99 density values from 0.02 to 1 with step
                equals to 0.01. For each value, there are 50 graphs. All graphs have the same number of nodes (|V| =
                100). You can download this graph data at <a>GraphDensityDataset</a>.
            </p>
            <p>
                For average local clustering coefficient, we have 3,450 graphs. There are 69 average local clustering
                coefficient values from 0.07 to 0.75 with step equals to 0.01. For each value, there are 50 graphs. All
                graphs have the same number of nodes (|V| = 100). You can download this graph data
                at <a>GraphACCDataset</a>.
            </p>
            <p>
                For our user study, we collected crowds' JND for graph density and average local clustering coefficient
                when the graph is displayed using different layout algorithms (Yifan Hu, Circular, and MDS). You can
                download this data at <a>GraphPerceptionResult</a>.
            </p>
        </div>
    }]
};