import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Slider,
  List,
  Icon,
  Statistic,
  Divider,
  Input,
  Checkbox,
  Modal,
  Tooltip,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import paperData from "../data/publication/papers.csv";
import { projectMetadata } from "../data/project/projectmetadata";
import { grantMetadata } from "../data/grants/grantmetadata";
import { hrefTargetAndRelHelper } from "../utils";

import "../styles/pubbrowser.css";

export default class PubBrowser extends PureComponent {
  constructor(props) {
    super(props);

    /**
     * Get the constant values
     */

    // console.log(paperData);
    const years = paperData.map((paper) => parseInt(paper.pub_year));
    const yearRange = [Math.min(...years), Math.max(...years)];

    const allPublicationTypes = Array.from(
      new Set(paperData.map((paper) => paper.pub_type))
    );

    // create the project list
    const { filterByProject } = props;
    let allProjects;

    if (filterByProject instanceof Array) {
      allProjects = projectMetadata
        .filter((p) => filterByProject.indexOf(p.idForPubBrowser) > -1)
        .map((p) => ({
          label: p.shortTitle,
          value: p.idForPubBrowser,
        }));
    } else {
      allProjects = projectMetadata.map((p) => ({
        label: p.shortTitle,
        value: p.idForPubBrowser,
      }));

      allProjects.push({
        label: "Other",
        value: 99,
      });
    }

    // const allProjects = projectMetadata.filter(p =>
    //     filterByProject === undefined || filterByProject.indexOf(p.idForPubBrowser) > -1
    // ).map(p => ({
    //     label: p.shortTitle,
    //     value: p.idForPubBrowser
    // }));

    /**
     * Default sorting options
     */
    this.state = {
      /**
       * Default filter values
       */
      nameKeyword:
        typeof props.nameKeyword === "string" ? props.nameKeyword : null,
      sortByYear:
        ["ascend", "descend"].indexOf(props.sortByYear) > -1
          ? props.sortByYear
          : "descend", // null|'ascend'|'descend'
      filterByYearRange:
        props.yearRange instanceof Array ? props.filterByYearRange : yearRange,
      filterByProject:
        props.filterByProject instanceof Array
          ? props.filterByProject
          : allProjects.map((p) => p.value),
      // filterByProject: props.filterByProject instanceof Array ? props.filterByProject : [1, 2, 3, 4, 10, 99],
      filterByType:
        props.filterByType instanceof Array
          ? props.filterByType
          : allPublicationTypes.filter((t) => t !== "Thesis"),
      filterByGrant:
        props.filterByGrant instanceof Array
          ? props.filterByGrant
          : grantMetadata.map((x) => x.value),
      filterNotFromOurLab:
        props.filterNotFromOurLab === undefined
          ? true
          : props.filterNotFromOurLab,

      /**
       * Enable/disable options
       */
      enableFilterByGrant:
        typeof props.enableFilterByGrant === "boolean"
          ? props.enableFilterByGrant
          : true,
      enableFilterByProject:
        typeof props.enableFilterByProject === "boolean"
          ? props.enableFilterByProject
          : true,

      /**
       * Constant values
       */
      yearRange,
      allPublicationTypes,
      allProjects,

      /**
       * Detail modal
       */
      enableDetailModal: false,
      detailModalPaper: {},
      citationTooltipContent: "Click to Copy",
    };

    let initialTotalNumber;

    if (filterByProject instanceof Array) {
      initialTotalNumber = this.filterAndSortData(true).length;
    } else {
      initialTotalNumber = this.filterAndSortData(true).length;
    }

    this.state.initialTotalNumber = initialTotalNumber;
  }

  filterAndSortData = (forgetType = false) => {
    const {
      nameKeyword,
      sortByYear,
      filterByYearRange,
      filterByType,
      filterByGrant,
      filterByProject,
      filterNotFromOurLab,
    } = this.state;

    let filteredPaperData = paperData;

    if (nameKeyword !== null) {
      const nameKeywordLowerCase = nameKeyword.toLowerCase();
      filteredPaperData = filteredPaperData.filter((paper) =>
        paper.title.toLowerCase().includes(nameKeywordLowerCase)
      );
    }

    if (sortByYear !== null) {
      filteredPaperData = filteredPaperData.sort((x, y) =>
        sortByYear === "ascend" ? 1 : -1 * (x.pub_year - y.pub_year)
      );
    }

    if (filterByYearRange instanceof Array) {
      filteredPaperData = filteredPaperData.filter(
        (paper) =>
          paper.pub_year >= filterByYearRange[0] &&
          paper.pub_year <= filterByYearRange[1]
      );
    }

    if (filterByType instanceof Array && !forgetType) {
      filteredPaperData = filteredPaperData.filter(
        (paper) => filterByType.indexOf(paper.pub_type) > -1
      );
    }

    console.log(filteredPaperData);

    if (filterByProject instanceof Array) {
      filteredPaperData = filteredPaperData.filter((paper) => {
        if (!paper.project_id) {
          return false;
        }

        let paperIDs =
          typeof paper.project_id === "number"
            ? [paper.project_id]
            : paper.project_id.split(",").map((p) => parseInt(p));

        // console.log(paper.project_id);
        // console.log(typeof paper.project_id);
        // console.log(paperIDs)

        for (let i = 0; i < paperIDs.length; i++) {
          if (filterByProject.indexOf(paperIDs[i]) > -1) {
            return true;
          }
        }

        return false;
      });
    }

    if (filterByGrant instanceof Array) {
      filteredPaperData = filteredPaperData.filter(
        (paper) =>
          filterByGrant
            .map((g) => parseInt(paper[g]))
            .reduce((a, b) => a + b, 0) > 0
      );
    }

    if (filterNotFromOurLab) {
      filteredPaperData = filteredPaperData.filter(
        (paper) => paper["not_from_our_lab"] === 0
      );
    }

    return filteredPaperData;
  };

  handleSearchInput = (event) => {
    const nameKeyword = event.target.value;
    this.setState({
      nameKeyword: nameKeyword.trim() === "" ? null : nameKeyword,
    });
  };

  handleYearChange = (range) => {
    this.setState({ filterByYearRange: range });
  };

  handlePublicationTypeChange = (filterByType) => {
    this.setState({ filterByType });
  };

  handleProjectChange = (filterByProject) => {
    this.setState({ filterByProject });
  };

  handleGrantChange = (filterByGrant) => {
    this.setState({ filterByGrant });
  };

  handleClickOnPaperItem = (detailModalPaper) => {
    this.setState({
      detailModalPaper,
      enableDetailModal: true,
    });
  };

  handleClosePaperModal = () => {
    this.setState({ enableDetailModal: false });
  };

  handleClickOnCitationBox = () => {
    this.setState({ citationTooltipContent: "Copied!" });
  };

  handleMouseOutCitationBox = (visible) => {
    if (!visible) {
      this.setState({ citationTooltipContent: "Click to Copy" });
    }
  };

  static computeLinkActions = (paper) => {
    let linkActions = [];

    if (paper.pdf_link !== null) {
      linkActions.push(
        <div key="pdflink">
          <Icon type="file-pdf" />
          <a
            {...hrefTargetAndRelHelper}
            // href={require(`../docs/publications/pdf/${paper.pdf_link}`)}
            href={`docs/publications/pdf/${paper.pub_year}/${paper.pdf_link}`}
            style={{ marginLeft: 4 }}
          >
            PDF
          </a>
        </div>
      );
    }

    if (paper.bibtex_file !== null) {
      linkActions.push(
        <div key="biblink">
          <Icon type="paper-clip" />
          <a
            {...hrefTargetAndRelHelper}
            href={`docs/publications/bibtex/${paper.bibtex_file}`}
            // href={require(`../docs/publications/bibtex/demo.bib`)}
            style={{ marginLeft: 4 }}
          >
            Bibtex
          </a>
        </div>
      );
    }

    if (paper.site_link !== null) {
      linkActions.push(
        <div key="sitelink">
          <Icon type="code" />
          <a
            {...hrefTargetAndRelHelper}
            href={paper.site_link}
            style={{ marginLeft: 4 }}
          >
            Project Website
          </a>
        </div>
      );
    }

    if (paper.publisher_link !== null) {
      linkActions.push(
        <div key="publisherlink">
          <Icon type="link" />
          <a
            {...hrefTargetAndRelHelper}
            href={paper.publisher_link}
            style={{ marginLeft: 4 }}
          >
            Publisher
          </a>
        </div>
      );
    }

    if (paper.video_link_0 !== null) {
      linkActions.push(
        <div>
          <Icon type="play-circle" />
          <a
            {...hrefTargetAndRelHelper}
            href={paper.video_link_0}
            style={{ marginLeft: 4 }}
          >
            Video
          </a>
        </div>
      );
    }

    return linkActions.length === 0 ? null : linkActions;
  };

  static insertDividers = (elList) => {
    if (elList === null) {
      return [];
    }

    const lenMinusOne = elList.length - 1;
    let newElList = Array(lenMinusOne * 2 + 1);

    for (let i = 0; i < lenMinusOne; i++) {
      newElList[i * 2] = elList[i];
      newElList[i * 2 + 1] = <Divider key="divider" type="vertical" />;
    }

    newElList[newElList.length - 1] = elList[lenMinusOne];

    return newElList;
  };

  render() {
    const paperListData = this.filterAndSortData();
    const { detailModalPaper } = this.state;

    return (
      <Row gutter={32} style={{ marginTop: 16 }}>
        <Modal
          title={`${detailModalPaper.title} (${detailModalPaper.pub_year})`}
          visible={this.state.enableDetailModal}
          footer={null}
          width="60%"
          closable
          maskClosable
          onCancel={this.handleClosePaperModal}
        >
          <Row gutter={32}>
            <Col span={8}>
              {/* disabled for now until we have a better way to display the thumbnail */}
              <div className="img-box" style={{ width: "100%" }}>
                <img
                  src={`../docs/publications/pdf/${
                    detailModalPaper.thumbnail_link === null
                      ? "placeholder.jpg"
                      : `${detailModalPaper.pub_year}/${detailModalPaper.thumbnail_link}`
                  }`}
                  // src={require(`../images/publications/papers/placeholder.png`)}
                  alt="thumbnail"
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
            <Col span={16}>
              <p style={{ fontSize: 13 }}>by {detailModalPaper.authors}</p>
              <p style={{ fontSize: 14 }}>{detailModalPaper.abstract}</p>

              <Row
                type="flex"
                justify="start"
                align="middle"
                style={{ marginTop: 12, marginBottom: 12 }}
              >
                {PubBrowser.insertDividers(
                  PubBrowser.computeLinkActions(detailModalPaper)
                )}
              </Row>

              <CopyToClipboard
                text={detailModalPaper.citation}
                onCopy={this.handleClickOnCitationBox}
              >
                <Tooltip
                  placement="top"
                  title={this.state.citationTooltipContent}
                  onVisibleChange={this.handleMouseOutCitationBox}
                >
                  <div
                    className="citation-box"
                    // onClick={this.handleClickOnCitationBox}
                  >
                    {detailModalPaper.citation}
                  </div>
                </Tooltip>
              </CopyToClipboard>
            </Col>
          </Row>
        </Modal>
        <Col
          span={6}
          style={{
            paddingTop: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Row
            type="flex"
            style={{
              flexDirection: "row",
            }}
          >
            <Statistic
              title="All Publications"
              value={this.state.initialTotalNumber}
              style={{ marginRight: 42 }}
            />
            <Statistic title="Listed" value={paperListData.length} />
          </Row>
          <Divider className="filter-panel-divider" orientation="left">
            Search Title
          </Divider>
          <Row
            style={{
              width: "100%",
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <Input
              style={{ width: "100%" }}
              defaultValue={this.state.nameKeyword}
              onChange={this.handleSearchInput}
            />
          </Row>
          <Divider className="filter-panel-divider" orientation="left">
            Years
          </Divider>
          <Row
            style={{
              width: "100%",
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 8,
              paddingRight: 16,
            }}
          >
            <Slider
              style={{ width: "100%" }}
              range={true}
              step={1}
              defaultValue={this.state.yearRange}
              marks={this.state.yearRange.reduce((accu, year) => {
                accu[year] = year.toString();
                return accu;
              }, {})}
              min={this.state.yearRange[0]}
              max={this.state.yearRange[1]}
              onChange={this.handleYearChange}
            />
          </Row>
          <Divider className="filter-panel-divider" orientation="left">
            Publication Types
          </Divider>
          <Checkbox.Group
            options={this.state.allPublicationTypes}
            defaultValue={this.state.filterByType}
            onChange={this.handlePublicationTypeChange}
          />
          <Divider className="filter-panel-divider" orientation="left">
            Projects
          </Divider>
          <Checkbox.Group
            disabled={!this.state.enableFilterByProject}
            options={this.state.allProjects}
            defaultValue={this.state.filterByProject}
            onChange={this.handleProjectChange}
          />
          <Divider className="filter-panel-divider" orientation="left">
            Grants
          </Divider>
          <Checkbox.Group
            disabled={!this.state.enableFilterByGrant}
            options={grantMetadata}
            defaultValue={this.state.filterByGrant}
            onChange={this.handleGrantChange}
          />
        </Col>
        <Col span={18}>
          <List
            // bordered={true}
            // split={true}
            itemLayout="vertical"
            // size="large"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize:
                this.props.pageSize === undefined ? 6 : this.props.pageSize,
              position: "bottom",
            }}
            dataSource={paperListData}
            renderItem={(paper, i) => (
              <Row
                key={i}
                className="paper-item-row"
                gutter={24}
                type="flex"
                align="middle"
              >
                {/* disabled for now until we have a better way to display the thumbnail */}

                <Col span={3}>
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    <img
                      src={`/docs/publications/pdf/${
                        paper.thumbnail_link === null
                          ? "placeholder.jpg"
                          : `${paper.pub_year}/${paper.thumbnail_link}`
                      }`}
                      // src={require(`../images/publications/papers/placeholder.png`)}
                      alt="thumbnail"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
                <Col span={21}>
                  <List.Item
                    onClick={() => this.handleClickOnPaperItem(paper)}
                    actions={PubBrowser.computeLinkActions(paper)}
                  >
                    <List.Item.Meta
                      title={`${paper.title} (${paper.pub_year})`}
                      description={paper.authors}
                    />
                  </List.Item>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>
    );
  }
}
