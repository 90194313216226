import React, {PureComponent} from 'react';
import {Row, Col, Tabs, List} from "antd";

import PeopleList from './PeopleList';
import PubBrowser from './PubBrowser';
import {projectMetadata} from '../data/project/projectmetadata';
// import {FoodEnergyWaterVAData} from '../data/project/foodenergywaterva';

const {TabPane} = Tabs;

// const keyToDataMapping = {
//     'food-energy-water-va': FoodEnergyWaterVAData
// };

export default class Project extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {projectKey} = this.props;
        const projectData = projectMetadata.find(p => p.key === projectKey);

        return (
            <Row>
                <Row
                    style={{marginTop: 16}}
                >
                    <h1
                        style={{
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        {projectData.title}
                    </h1>
                </Row>
                {
                    (!projectData.lastUpdated || 0 === projectData.length)
                        ? null
                        :
                        <Row
                            style={{marginBottom: 18}}
                        >
                            <span
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                                Date of Last Update: {projectData.lastUpdated}
                            </span>
                        </Row>
                }
                <Tabs defaultActiveKey={projectData.key + projectData.tabs[0].tabName}>
                    {projectData.tabs.map(projectTab => {
                        let newTabPane;
                        const newTabKey = projectData.key + projectTab.tabName;

                        if (projectTab.type === 'normal') {
                            newTabPane = (
                                <TabPane
                                    key={newTabKey}
                                    tab={projectTab.tabName}
                                >
                                    {projectTab.content}
                                </TabPane>
                            );
                        } else if (projectTab.type === 'software') {
                            newTabPane = (
                                <TabPane
                                    key={newTabKey}
                                    tab={projectTab.tabName}
                                >
                                    <SoftwareList
                                        listData={projectTab.software}
                                    />
                                </TabPane>
                            );
                        } else if (projectTab.type === 'personnel') {
                            newTabPane = (
                                <TabPane
                                    key={newTabKey}
                                    tab={projectTab.tabName}
                                >
                                    <PeopleList
                                        inProjectFilter={projectData.key}
                                        sortByRole={true}
                                    />
                                </TabPane>
                            );
                        } else if (projectTab.type === 'publications') {
                            newTabPane = (
                                <TabPane
                                    key={newTabKey}
                                    tab={projectTab.tabName}
                                >
                                    <PubBrowser
                                        filterByProject={[projectData.idForPubBrowser]}
                                        filterNotFromOurLab={false}
                                        enableFilterByProject={false}
                                    />
                                </TabPane>
                            );
                        }

                        return newTabPane;
                    })}
                </Tabs>
            </Row>
        );
    }
}


class SoftwareList extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {listData} = this.props;

        return (
            <List
                itemLayout="vertical"
                size="large"
                dataSource={listData}
                renderItem={(softwareData, i) =>
                    <Row
                        gutter={24}
                        type="flex"
                        align="middle"
                    >
                        <Col span={6}>
                            <img
                                src={softwareData.image}
                                alt=""
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col span={18}>
                            <List.Item
                                key={i}
                            >
                                <List.Item.Meta
                                    title={softwareData.title}
                                    // description={softwareData.publication}
                                />
                                {softwareData.description}
                                {
                                    (softwareData.publication.length > 0)
                                    ? <h3><u>Publications:</u></h3>
                                    : null
                                }
                                {softwareData.publication}
                            </List.Item>
                        </Col>
                    </Row>
                }
            />
        );
    }
}