import React, {PureComponent} from 'react';
import {List, Icon, Row, Col} from "antd";

import {peopleData, sortByRoleRuleObj} from '../data/people';
import {hrefTargetAndRelHelper} from "../utils";


export default class PeopleList extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    static renderLinkActions = (person) => {
        let actions = [];

        if (person.homepage !== undefined) {
            actions.push(<div>
                <Icon type="home"/>
                <a
                    {...hrefTargetAndRelHelper}
                    href={person.homepage}
                    style={{marginLeft: 4}}
                >
                    Homepage
                </a>
            </div>);
        }

        if (person.linkedin !== undefined) {
            actions.push(<div>
                <Icon type="linkedin"/>
                <a
                    {...hrefTargetAndRelHelper}
                    href={person.linkedin}
                    style={{marginLeft: 4}}
                >
                    LinkedIn
                </a>
            </div>);
        }

        return actions.length === 0 ? null : actions;
    };

    render() {

        const {roleFilter, isAlumniFilter, isLabMemberFilter, inProjectFilter, sortByRole} = this.props;
        let targetPeopleData = peopleData;

        if (roleFilter !== undefined) {
            targetPeopleData = targetPeopleData.filter(p => {
                if (p.role instanceof Array) {
                    return p.role.indexOf(roleFilter) >= 0;
                }
                else {
                    return p.role === roleFilter;
                }
            });
        }

        if (isAlumniFilter !== undefined) {
            targetPeopleData = targetPeopleData.filter(p => p.isAlumni === isAlumniFilter);
        }

        if (inProjectFilter !== undefined) {
            targetPeopleData = targetPeopleData.filter(p => p.inProject.indexOf(inProjectFilter) >= 0);
        }

        if (isLabMemberFilter !== undefined) {
            targetPeopleData = targetPeopleData.filter(p => p.isLabMember === isLabMemberFilter);
        }

        if (sortByRole === true) {
            targetPeopleData = targetPeopleData.sort((a, b) => sortByRoleRuleObj[a.role] - sortByRoleRuleObj[b.role]);
        }

        return (
            <List
                itemLayout="vertical"
                size='large'
                dataSource={targetPeopleData}
                renderItem={(person, i) => (
                    <Row
                        key={i}
                        gutter={24}
                        type="flex"
                        align="middle"
                        style={{marginBottom: 16}}
                    >
                        <Col span={6}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <img
                                    style={{width: '75%'}}
                                    alt="portrait"
                                    src={person.image}
                                />
                            </div>
                        </Col>
                        <Col span={18}>
                            <List.Item
                                key={person.name}
                                actions={PeopleList.renderLinkActions(person)}
                            >
                                <List.Item.Meta
                                    title={person.name}
                                    description={person.title}
                                />
                                {person.bio}
                            </List.Item>
                        </Col>
                    </Row>
                )}
            />

        );
    }
}