import React, {PureComponent} from 'react';
import {List, Row, Col, Card, Modal} from "antd";

import photoData from '../data/photos/image_captions.csv';

export default class Photo extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            currentPhotoFile: null,
            currentPhotoCaption: null
        }
    }

    handleCardClick = (file, caption) => {
        this.setState({
            modalVisible: true,
            currentPhotoFile: file,
            currentPhotoCaption: caption
        });
    };

    render() {
        return (
            <Row>
                <Col span={24}>
                    <Modal
                        visible={this.state.modalVisible}
                        centered
                        title={this.state.currentPhotoCaption}
                        width={800}
                        footer={null}
                        closable={true}
                        maskClosable={true}
                        onCancel={() => this.setState({modalVisible: false})}
                    >
                        <img
                            src={`/images/labphotos/${this.state.currentPhotoFile}`}
                            alt={`${this.state.currentPhotoCaption}`}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />
                    </Modal>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 3,
                        }}
                        dataSource={photoData}
                        renderItem={photo => (
                            <List.Item>
                                <Card
                                    hoverable
                                    cover={
                                        <img
                                            src={`/images/labphotos/${photo.photoFile}`}
                                            alt={`${photo.photoCaption}`}
                                        />
                                    }
                                    onClick={() => this.handleCardClick(photo.photoFile, photo.photoCaption)}
                                >
                                    <Card.Meta
                                        description={`${photo.photoCaption}`}
                                    />
                                </Card>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        );
    }
}