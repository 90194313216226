import React from 'react';


export const NEWS2020 = {
    name: '2020',
    news: [{
        date: 'Nov. 4, 2020',
        content: <span>Professor Maciejewski is giving a keynote talk at ACM SIGSPATIAL 2020.</span>
    }, {
        date: 'Oct. 27, 2020',
        content: <span>Joint work with our colleagues at Kaiserslautern and LANL, "Interpolation of Scientific Image Databases," was accepted to the International Conference on Physical Modeling for Virtual Manufacturing.</span>
    }, {
        date: 'Oct. 7, 2020',
        content: <span>Professor Maciejewski presented his keynote talk "Having fun in the data deluge" at the International Symposium on Visual Computing (ISVC 2020).</span>
    }, {
        date: 'Sept. 23, 2020',
        content: <span>Yuxin Ma's paper "Visual Analysis of Class Separations with Locally Linear Segments" earned the IEEE TVCG Replicability Stamp.</span>
    }, {
        date: 'Aug. 18, 2020',
        content: <span>Jonas Lukasczyk's short paper, "Cinema Darkroom: A Deferred Rendering Framework for Large-Scale Datasets," has been accepted to LDAV 2020. Congrats Jonas!</span>
    }, {
        date: 'Aug. 14, 2020',
        content: <span>Tiankai Xie and Yuxin Ma's paper "Auditing the Sensitivity of Graph-based Ranking with Visual Analytic" has been accepted to IEEE VAST 2020. Congratulations!</span>
    }, {
        date: 'Aug. 14, 2020',
        content: <span>Yuxin Ma and Arlen Fan's paper "A Visual Analytics Framework for Explaining and Diagnosing Transfer Learning Processes" has been accepted to IEEE VAST 2020. Congratulations!</span>
    }, {
        date: 'Aug. 14, 2020',
        content: <span>Jonas Lukasczyk's paper "Localized Topological Simplification of Scalar Data" has been accepted to IEEE SciVis 2020. Congratulations!</span>
    }, {
        date: 'Aug. 14, 2020',
        content: <span>Joint work ("Diagnosing Concept Drift in Streaming Data") led by our colleagues at Tsinghua University has been accepted to IEEE VAST 2020. Congratulations!</span>
    }, {
        date: 'July 31, 2020',
        content: <span>Undergraduate researcher Ryan Kemmer had his first paper accepted with guidance from Dr. Adolfo Escobedo - "Enhancing Collective Estimates by Aggregating Cardinal and Ordinal Inputs" will be presented at the AAAI Conference on Human Computation and Crowdsourcing (HCOMP2020). Congrats Ryan!</span>
    }, {
        date: 'July 15, 2020',
        content: <span>Yuxin Ma's paper, "Visual Analysis of Class Separations with Locally Linear Segments" has been accepted to IEEE TVCG. Congrats Yuxin!</span>
    }, {
        date: 'June 13, 2020',
        content: <span>Yuxin and Prannoy's paper, "A Visual Analytics System for Oil Spill Response and Recovery" has been accepted to IEEE CG&A. Congrats everyone!</span>
    }, {
        date: 'May 18, 2020',
        content: <span>Jonas Lukasczyk was awarded the EuroVis 2020 Best PhD Award. Congrats Jonas!</span>
    }, {
        date: 'May 16, 2020',
        content: <span>Work with Hanghang Tong and the IDEA Lab @UIUC, "InFoRM: Individual Fairness on Graph Mining" by Jian Kang, Jingrui He and Hanghang Tong has been accepted to KDD'20! Congrats!</span>
    }, {
        date: 'May 13, 2020',
        content: <span>Weihai Shen graduated with his Masters degree from ASU Computer Science, congrats Weihai!</span>
    }, {
        date: 'May 5, 2020',
        content: <span>The Patent Office has agreed to grant a new U.S. patent directed for our work on first-person perspective, patient-registered medical image visualization! Great working with David Frakes and his team!</span>
    }, {
        date: 'Jan. 11, 2020',
        content: <span>Work with Jingrui He and the iSAIL Lab @UIUC was accepted to WWW 2020 - "Crowd Teaching with Noisy Labels" by Y. Zhou, A. Nelkurthi, R. Maciejewski, and J. He. Congrats!</span>
    }]
};