export const grantMetadata = [{
    label: 'NSF #1639227',
    value: 'NSF1639227'
}, {
    label: 'NSF #1350573',
    value: 'NSF1350573'
}, {
    label: 'DHS #2017-ST-061-QA0001',
    value: '2017-ST-061-QA0001'
}, {
    label: 'NSF #1939725',
    value: 'NSF1939725'
}, {
    label: 'Other',
    value: 'Other'
}];