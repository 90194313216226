import React, {PureComponent} from 'react';
import {Tabs} from "antd";

import PeopleList from './PeopleList';
import Photo from './Photo';

const peopleTypes = [{
    name: 'Principal Investigator',
    roleFilter: 'pi',
    isAlumniFilter: false
}, {
    name: 'Postdocs',
    roleFilter: 'postdoc',
    isAlumniFilter: false
}, {
    name: 'PhD Students',
    roleFilter: 'phd',
    isAlumniFilter: false
}, {
    name: 'MS Students',
    roleFilter: 'master',
    isAlumniFilter: false,
}, {
    name: 'Undergraduates',
    roleFilter: 'undergrad',
    isAlumniFilter: false
}];

const alumniPeopleTypes = [{
    name: 'Staff',
    roleFilter: 'staff',
    isAlumniFilter: true
}, {
    name: 'Postdocs',
    roleFilter: 'postdoc',
    isAlumniFilter: true
}, {
    name: 'PhD Students',
    roleFilter: 'phd',
    isAlumniFilter: true
}, {
    name: 'MS Students',
    roleFilter: 'master',
    isAlumniFilter: true,
}, {
    name: 'Undergraduates',
    roleFilter: 'undergrad',
    isAlumniFilter: true
}];

export default class People extends PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <Tabs defaultActiveKey={peopleTypes[0].name}>
                {peopleTypes.map(t =>
                    <Tabs.TabPane
                        tab={t.name}
                        key={t.name}
                    >
                        <PeopleList
                            roleFilter={t.roleFilter}
                            isAlumniFilter={t.isAlumniFilter}
                            isLabMemberFilter={true}
                        />
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane
                    tab="Alumni"
                    key="alumni"
                >
                    <Tabs
                        defaultActiveKey={alumniPeopleTypes[0].name}
                        tabPosition="left"
                    >
                        {alumniPeopleTypes.map(t =>
                            <Tabs.TabPane
                                tab={t.name}
                                key={t.name}
                            >
                                <PeopleList
                                    roleFilter={t.roleFilter}
                                    isAlumniFilter={t.isAlumniFilter}
                                    isLabMemberFilter={true}
                                />
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Lab Pictures"
                    key="labpictures"
                >
                    <Photo/>
                </Tabs.TabPane>
            </Tabs>
        );
    }
}