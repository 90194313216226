import {FoodEnergyWaterVAData} from './foodenergywaterva';
import {SpatiotemporalVA} from "./spatiotemporalva";
import {XAI} from "./explainableai";
import {GraphAnalytics} from "./graphanalytics";
import {SciVis} from "./scivis";


export const projectMetadata = [
    FoodEnergyWaterVAData,
    SpatiotemporalVA,
    XAI,
    GraphAnalytics,
    SciVis
];