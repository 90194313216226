import React from 'react';

import {hrefTargetAndRelHelper} from '../../utils';

// images
import cirruculum from '../../images/project/spatiotemporalva/cirruculum.png';
import uncertaintyImage from '../../images/project/spatiotemporalva/uncertainty.png';
import themeParkVA from '../../images/project/spatiotemporalva/ThemeParkVA.png';
import YifanIV16 from '../../images/project/spatiotemporalva/Yifan-IV16.png';
import IVIID from '../../images/project/spatiotemporalva/IVIID.png';
import nameProfiler from '../../images/project/spatiotemporalva/telephone_book2.png';
import mapGeneration from '../../images/project/spatiotemporalva/typomaps_small.png';


export const SpatiotemporalVA = {
    idForPubBrowser: 2,
    lastUpdated: 'June 1, 2020',
    key: 'spatiotemporal-va',
    shortTitle: 'Spatiotemporal Visual Analytics',
    title: 'NSF:CAREER: A Visual Analysis Approach to Space-Time Data Exploration',
    tabs: [{
        tabName: 'Abstract',
        type: 'normal',
        content: <div>
            <p>
                From smart phones to fitness trackers to sensor enabled buildings, data is currently being collected at
                an unprecedented rate. Now, more than ever, data exists that can be used to gain insight into how policy
                decisions can impact our daily lives. For example, one can imagine using data to help predict where
                crime may occur next or inform decisions on police resource allocations or diet and activity patterns
                could be used to provide recommendations for improving an individual's overall health and well-being.
                Underlying all of this data are measurements with respect to space and time. However, finding
                relationships within datasets and accurately representing these relationships to inform policy changes
                is a challenging problem. This research addresses fundamental questions of how we can effectively
                explore such space-time data in order to enhance knowledge discovery and dissemination. This research
                both extends traditional visual representations and develops novel views for showing how correlations,
                clusters and other various spatial dynamics change over time. Broader impacts of the research program
                include: 1) enhanced infrastructure for research and education in the form of new visual analytics
                algorithms and open source software, 2) broad dissemination of visual analysis methods across various
                domains including geography, urban planning, and public health, and 3) impacts on society including the
                dissemination of novel tools and methods for improved public health and safety. The primary educational
                goals of this CAREER program are to increase exposure to crucial but highly unavailable visual analytic
                technologies and to broaden participation in data science and engineering. Toward those ends, the Visual
                Analytics Education program will engage broad student populations (undergraduate and graduate) through
                innovative curricula focusing on visual data analysis and the core technologies that drive the research
                program (visual analytics tools). By focusing on those technologies and their synergy in the research
                program, the education program directly integrates the proposed research with education. The programs
                will benefit multiple groups (researchers, patients, students, underrepresented groups) and institutions
                (academia, industry, healthcare, education) both locally and globally.
            </p>
            <p>
                For spatial data, the translation of such data into a visual form allows users to quickly see patterns,
                explore summaries and relate domain knowledge about underlying geographical phenomena that would not be
                apparent in tabular form. However, several critical challenges arise when visualizing and exploring
                these large spatiotemporal datasets. While, the underlying geographical component of the data lends
                itself well to univariate visualization in the form of traditional cartographic representations (e.g.,
                choropleth, isopleth, dasymetric maps), as the data becomes multivariate, cartographic representations
                become more complex. Multivariate color maps, textures, small multiples and 3D views have been employed
                as means of increasing the amount of information that can be conveyed when plotting spatial data to a
                map. However, each of these methods has their own limitations. Multivariate color maps and textures
                result in cognitive overload where much time is spent trying to separate data elements in the visual
                channel. In 3D, occlusion and clutter remain fundamental challenges for effective visual data
                understanding. Utilizing small multiples can help in side-by-side comparison, but their scalability is
                limited by the available screen space and the cognitive overhead associated with pairwise comparisons.
                Instead of being confined to the original spatiotemporal domain, this proposal seeks to both extend
                traditional visual representations and develop novel views for showing how correlations, clusters and
                other various spatial dynamics change over time. Underlying these novel views is also the need for
                visual representations in which the manipulation of the representation is directly tied to the
                underlying computational analytics. Specifically, this research focuses on datasets from urban planning,
                geography, public health and crime to address 1) the extraction of semi-supervised templates for spatial
                and temporal aggregation; 2) the development of interaction techniques for visual steering and
                classification of spatiotemporal data; 3) the integration of multiple families of anomaly detection
                algorithms and information theoretic methods for semi-supervised anomaly detection, and; 4) novel
                algorithms for the extraction of flow fields from spatiotemporal data. Additional information can be
                found at the lab website (<a
                href="http://vader.lab.asu.edu" {...hrefTargetAndRelHelper} >http://vader.lab.asu.edu/</a>) including
                open source software, course learning modules and podcasts.
            </p>
            <p>
                <b>Funding:</b> This material is based upon work supported by the National Science Foundation under
                Grant No. 1350573. Disclaimer: Any opinions, findings, and conclusions or recommendations expressed in
                this material are those of the author(s) and do not necessarily reflect the views of the National
                Science Foundation.
            </p>
        </div>
    }, {
        tabName: 'Publications',
        type: 'publications'
    }, {
        tabName: 'Education',
        type: 'normal',
        content: <div>
            <p>
                This project has helped develop the Visual Analytics Education program to engage broad student
                populations (undergraduate and graduate) through innovative curricula focusing on visual data analysis.
                We have worked with partners at the Department of Homeland Security Visual Analytics for Command Control
                and Interoperability (VACCINE) and Center for Accelerating Operational Efficiency (CAOE) Centers of
                Excellence to develop a program to expand the teaching of visual analytics at Minority Serving
                Institutions (MSI). The program will focus on preparing MSI faculty to teach visual analytics courses.
            </p>
            <p>
                <img
                    src={cirruculum}
                    alt="cirruculum"
                />
            </p>
            <p>
                <ul>
                    <li>Presentation: Integrating Visual Analytics Into the Classroom. <a
                        href="docs/project/spatiotemporalva/">PDF</a></li>
                    <li>Presentation: Introduction to Tableau. <a>PDF</a>, World Bank Indicators Example <a>Data</a>,
                        Superstore Sales Example <a>Data</a>, Grade Example <a>Data</a></li>
                    <li>Presentation: Presentation: Introduction to NodeXL. <a>PDF</a></li>
                </ul>
            </p>
            <p>
                To further enable undergraduate research in computer science, this program also supports Arizona State
                University's Introduction to Undergraduate Research course. The <a>syllabus</a> for this course provides
                details.
            </p>
            <p>
                Finally, this work has also supported the development of online educational resources to be incorporated
                into an online MS degree program at Arizona State University. The <a>open versions</a> of the Data
                Exploration and Visual Analysis course modules are hosted by Coursera.
            </p>
        </div>
    }, {
        tabName: 'Software',
        type: 'software',
        software: [{
            title: 'Exploring the Sensitivity of Choropleths under Attribute Uncertainty',
            description: <p>
                The choropleth map is an essential tool for spatial data analysis. However, the
                underlying attribute values of a spatial unit greatly influence the statistical analyses and map
                classification procedures when generating a choropleth map. If the attribute values incorporate a range
                of uncertainty, a critical task is determining how much the uncertainty impacts both the map
                visualization and the statistical analysis. In this paper, we present a visual analytics system that
                enhances our understanding of the impact of attribute uncertainty on data visualization and statistical
                analyses of these data. Our system consists of a parallel coordinates-based uncertainty specification
                view, an impact river and impact matrix visualization for region-based and simulation-based analysis,
                and a dual-choropleth map and t-SNE plot for visualizing the changes in classification and spatial
                autocorrelation over the range of uncertainty in the attribute values. We demonstrate our system
                through three use cases illustrating the impact of attribute uncertainty in geographic analysis.
            </p>,
            image: uncertaintyImage,
            publication: [<p>
                Zhaosong Huang, Yafeng Lu, Elizabeth A. Mack, Wei Chen, Ross Maciejewski. Exploring the Sensitivity of
                Choropleths under Attribute Uncertainty. IEEE Transactions on Visualization and Computer Graphics, 2019
                (to appear). <a style={{marginRight: 12}}
                                href="docs/publications/pdf/2019/GeoUncertainty.pdf" {...hrefTargetAndRelHelper}>PDF</a>
                <a style={{marginRight: 12}}
                   href="https://github.com/VADERASU/Choropleths-Attribute-Uncertainty" {...hrefTargetAndRelHelper}>Code</a>
            </p>]
        }, {
            title: 'A Visual Analytics Framework for Exploring Theme Park Dynamics',
            description: <p>
                In 2015, the top ten largest amusement park corporations saw a combined annual attendance of over 400
                million visitors. Daily average attendance in some of the most popular theme parks in the world can
                average 44,000 visitors per day. These visitors ride attractions, shop for souvenirs and dine at local
                establishments; however, a critical component of their visit is the overall park experience.This
                experience depends on the wait time for rides, the crowd flow in the park, and various other factors
                linked to the crowd dynamics and human behavior. As such, better insight into visitor behavior can help
                theme parks devise competitive strategies for improved customer experience. Research into the use of
                attractions, facilities and exhibits can be studied, and as behavior profiles emerge, park operators can
                also identify anomalous behaviors of visitors which can improve safety and operations. We present a
                visual analytics framework for analyzing crowd dynamics in theme parks. Our proposed framework is
                designed to support behavioral analysis by summarizing patterns and detecting anomalies. We provide
                methodologies to link visitor movement data, communication data, and park infrastructure data. This
                combination of data sources enables a semantic analysis of who, what, when and where, enabling analysts
                to explore visitor-visitor interactions and visitor-infrastructure interactions. Analysts can identify
                behaviors at the macro level through semantic trajectory clustering views for group behavior dynamics,
                as well as at the micro level using trajectory traces and a novel visitor network analysis view. We
                demonstrate the efficacy of our framework through two case studies of simulated theme park visitors.
            </p>,
            image: themeParkVA,
            publication: [<p>
                Michael Steptoe, Robert Krueger, Yifan Zhang, Xing Liang, Wei Luo, Rolando Garcia, Sagarika Kadambi,
                Thomas Ertl, Ross Maciejewski. VAST challenge 2015: Grand challenge - Team VADER/VIS Award for
                Outstanding Comprehensive Submission. Proceedings of the IEEE Visual Analytics Science and Technology
                Challenge Workshop, 2015. <br/>
                <a style={{marginRight: 12}}
                   href="docs/publications/pdf/2015/VAST_GC15.pdf" {...hrefTargetAndRelHelper}>PDF</a>
                <a style={{marginRight: 12}} href="https://youtu.be/LUZr3qEt7Qo" {...hrefTargetAndRelHelper}>Video1</a>
                <a style={{marginRight: 12}} href="https://youtu.be/DL6A__IWRn4" {...hrefTargetAndRelHelper}>Video2</a>
                <a style={{marginRight: 12}} href="https://youtu.be/VGXrxlxstt4" {...hrefTargetAndRelHelper}>Video3</a>
                <a style={{marginRight: 12}}
                   href="http://www.vacommunity.org/VAST+Challenge+2015" {...hrefTargetAndRelHelper}>VAST Challenge
                    Data</a>
                <a style={{marginRight: 12}}
                   href="http://www.cs.ucf.edu/~gsolmaz/projects/TP.html" {...hrefTargetAndRelHelper}>Theme Park
                    Simulation</a>
                <a style={{marginRight: 12}}
                   href="http://vader.lab.asu.edu/ThemeParkVA" {...hrefTargetAndRelHelper}>Demo</a>
            </p>]
        }, {
            title: 'Quantifying the Visual Impact of Classification Boundaries in Choropleth Maps',
            description: <p>One critical visual task when using choropleth maps is to identify spatial clusters in the
                data. If spatial units have the same color and are in the same neighborhood, this region can be visually
                identified as a spatial cluster. However, the choice of classification method used to create the
                choropleth map determines the visual output. The critical map elements in the classification scheme are
                those that lie near the classification boundary as those elements could potentially belong to different
                classes with a slight adjustment of the classification boundary. Thus, these elements have the most
                potential to impact the visual features (i.e., spatial clusters) that occur in the choropleth map. We
                present a methodology to enable analysts and designers to identify spatial regions where the visual
                appearance may be the result of spurious data artifacts. The proposed methodology automatically detects
                the critical boundary cases that can impact the overall visual presentation of the choropleth map using
                a classification metric of cluster stability. The map elements that belong to a critical boundary case
                are then automatically assessed to quantify the visual impact of classification edge effects. Our
                results demonstrate the impact of boundary elements on the resulting visualization and suggest that
                special attention should be given to these elements during map design.</p>,
            image: YifanIV16,
            publication: [<p>
                Yifan Zhang and Ross Maciejewski. Quantifying the Visual Impact of Classification Boundaries in
                Choropleth Maps. IEEE Transactions on Visualization and Computer Graphics, 23(1): 371-380, 2017.
                <br/>
                <a style={{marginRight: 12}}
                   href="/docs/publications/pdf/2016/Yifan-IV16.pdf" {...hrefTargetAndRelHelper}>PDF</a>
                <a style={{marginRight: 12}}
                   href="http://vader.lab.asu.edu/MapCluster" {...hrefTargetAndRelHelper}>Demo</a>
            </p>]
        }, {
            title: 'Geovisual Analytics/Interactive Visual Analytics Interface for Industrial Diversity',
            description: <p>The industrial composition of places has received considerable attention because of the
                widespread belief that industrial diversity buffers regional economies from economic shocks.
                Subsequently, a variety of toolkits and indices have been developed with the goal of better capturing
                the compositional dynamics of regions. Although useful, a key drawback of these indices is their static
                nature, which limits the utility of these indices in a space-time context. So we developed an interface
                called IVIID (Interactive Visualization tool for Indices of Industrial Diversity), which is a visual
                analytics tool developed specically for the interactive analysis and visualization of local measures of
                industrial composition for areal data. The interactivity and query functionality of this interface
                provided overcomes several of the obstacles to understanding composition through space and time that
                prior toolkits and comparative static approaches have been unable to address.</p>,
            image: IVIID,
            publication: [<p>
                Elizabeth A. Mack, Yifan Zhang, Sergio Rey, Ross Maciejewski. IVIID: A Visual Analytics Tool for the
                Spatio-Temporal Analysis of Industrial Composition. Journal of Geographical Systems, 16(2): 183-209,
                2014. <br/>
                <a href="https://github.com/yifantastic/IVIID" {...hrefTargetAndRelHelper}>Code</a>
            </p>]
        }, {
            title: 'Surname Search',
            description: <p>
                In this project, we provide an interactive flow map to visualize potential migration patterns of
                surnames around the globe. This work utilizes Internet search queries from the Worldnames Project and
                uses the density of search locations to determine the node and leaf structures of a flow map. The
                mapping technique utilized in this work is a variant of geometric minimal Steiner arborescences called
                the spiral tree. The current implementation is developed in Javascript to allow for interactive online
                exploration. Nodes and flow lines can be interactively modified to allow for aesthetic changes of color
                and layout. Anecdotal evidence suggests that these mappings coincide with known family migration
                patterns and can provide interesting insight into the geography of amateur genealogy.

                Another work explores the development of a visual analytics tool for geodemographic exploration in an
                online environment. We mine 78 million records from the United States public telephone directories, link
                the location data to demographic data (specifically income) from the United States Census Bureau, and
                allow users to interactively compare distributions of names with regards to spatial location similarity
                and income. In order to enable interactive similarity exploration, we explore methods of pre-processing
                the data as well as on-the-fly lookups. As data becomes larger and more complex, the development of
                appropriate data storage and analytics solutions has become even more critical when enabling online
                visualization. We discuss problems faced in implementation, design decisions and directions for future
                work.
            </p>,
            image: nameProfiler,
            publication: [<p>
                Feng Wang, Brett Hansen, Ryan Simmons, Ross Maciejewski. The Name Profiler Toolkit. IEEE Computer
                Graphics & Applications, 37(5): 61-71, 2017.<br/>
                <a style={{marginRight: 12}}
                   href="/docs/publications/pdf/2017/TheNameProfilerToolkit.pdf" {...hrefTargetAndRelHelper}>PDF</a>
                <a style={{marginRight: 12}}
                   href="https://demo.vaderlab.org/name/" {...hrefTargetAndRelHelper}>Demo</a>
            </p>, <p>
                Feng Wang, Jose Ibarra, Adnan Muhammad, Paul A. Longley, Ross Maciejewski. What's in a Name? Data
                Linkage, Demography and Visual Analytics. EuroVis Workshop on Visual Analytics, June, 2014.<br/>
                <a style={{marginRight: 12}}
                   href="/docs/publications/pdf/2014/Names.pdf" {...hrefTargetAndRelHelper}>PDF</a>
                <a style={{marginRight: 12}}
                   href="https://demo.vaderlab.org/name/" {...hrefTargetAndRelHelper}>Demo</a>
                <a style={{marginRight: 12}} href="https://youtu.be/pANl4YJ1C5I" {...hrefTargetAndRelHelper}>Video</a>
            </p>, <p>
                Yifan Zhang, Adnan Muhammad, Paul A. Longley, Ross Maciejewski. Exploring Geo-Genealogy Using Internet
                Search Histories. Journal of Maps, 9(4):481-485, 2013.<br/>
                <a style={{marginRight: 12}}
                   href="http://www.tandfonline.com/doi/abs/10.1080/17445647.2013.824391#.Uh_iaBushcY" {...hrefTargetAndRelHelper}>PDF</a>
                <a style={{marginRight: 12}}
                   href="http://www.uncertaintyofidentity.com/SurnameSearch.html" {...hrefTargetAndRelHelper}>Demo</a>
                <a style={{marginRight: 12}}
                   href="https://github.com/yifantastic/FlowMap" {...hrefTargetAndRelHelper}>Source</a>
            </p>]
        }, {
            title: 'Typographic Map Generation',
            description: <p>
                This project explores method for automatically building typographic maps that merge text and spatial
                data into a visual representation where text alone forms the graphical features. The technique accepts a
                vector representation of a geographic map and spatializes the textual labels in the space onto polylines
                and polygons based on user-defined visual attributes and constraints. Our sample spatializes shape files
                from the OpenStreetMap project into typographic maps for any region into a PNG image. Eventually, our
                goal is to release this program as a web application for anyone to try.
            </p>,
            image: mapGeneration,
            publication: [<p>
                Shehzad Afzal, Ross Maciejewski, Yun Jang, Niklas Elmqvist, and David S. Ebert. Spatial Text
                Visualization Using Automatic Typographic Maps. IEEE Transactions on Visualization and Computer
                Graphics, 2012.<br/>
                <a href="/docs/publications/pdf/2012/Afzal_TypoMaps.pdf">PDF</a>
            </p>]
        }]
    }, {
        tabName: 'Personnel',
        type: 'personnel',
        filter: 'spatiotemporal-va'
    }]
}